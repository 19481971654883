import {
  createAutoRecommendation,
  CreateAutoRecommendationPayload,
  createRecommendation,
  CreateRecommendationPayload,
  deleteRecommendation,
  getRecommendation,
  sendRecommendation,
  updateRecommendation,
  withdrawRecommendations
} from '@/api';
import {
  AddRecommendationMutationResult,
  RecommendationViewResponse
} from '@/types/recommendation';
import { useInvalidateOnSuccessMutation } from '@/utils';
import { useQuery, UseQueryOptions } from 'react-query';
import { BusinessVertical } from '@/enums/businessVertical';

interface UseCreateRecommendationPayload {
  categoryId?: string;
  businessVertical: BusinessVertical;
}

export const useRecommendation = (
  id: string,
  options: UseQueryOptions<
    RecommendationViewResponse,
    unknown,
    RecommendationViewResponse,
    string[]
  >
) =>
  useQuery(
    ['recommendationProducts', id],
    () => getRecommendation(id),
    options
  );

// fixme: Separate in two hooks to avoid confusing behavior?
export const useCreateRecommendation = ({
  insuranceId,
  categoryId,
  businessVertical,
  locationId
}: CreateRecommendationPayload) =>
  useInvalidateOnSuccessMutation<
    AddRecommendationMutationResult,
    unknown,
    void,
    unknown
  >('location', (payload: UseCreateRecommendationPayload) => {
    return createRecommendation({
      locationId,
      insuranceId,
      categoryId: categoryId || payload.categoryId,
      /**
       * we shouldn't need to pass this - it should be determined by the BE
       */
      businessVertical:
        businessVertical || payload.businessVertical || 'default'
    });
  });

export const useCreateAutoRecommendation = (locationId: string) =>
  useInvalidateOnSuccessMutation(
    ['location', locationId],
    ({ insuranceId, categoryId }: CreateAutoRecommendationPayload) =>
      createAutoRecommendation({
        locationId,
        insuranceId,
        categoryId
      })
  );

export const useDeleteRecommendations = () =>
  useInvalidateOnSuccessMutation('location', (ids: string[]) =>
    deleteRecommendation(ids)
  );

export const useWithdrawRecommendations = () =>
  useInvalidateOnSuccessMutation('location', (ids: string[]) =>
    withdrawRecommendations(ids)
  );

export const useUpdateRecommendationsNotes = (id: string) =>
  useInvalidateOnSuccessMutation(
    ['recommendationProducts', id],
    (notes: string[]) => updateRecommendation({ id, notes })
  );

export const useSendRecommendations = () =>
  useInvalidateOnSuccessMutation('location', (ids: string[]) =>
    sendRecommendation(ids)
  );

export const useUpdateRecommendationAttributes = (id: string) =>
  useInvalidateOnSuccessMutation(
    ['recommendationProducts', id],
    (visibleAttributes: string[]) =>
      updateRecommendation({ id, visibleAttributes })
  );

export const useUpdateRecommendationSearchCriteria = (id: string) =>
  useInvalidateOnSuccessMutation(
    ['recommendationProducts', id],
    (searchCriteria: string[]) =>
      updateRecommendation({ id, searchCriteria, userSearchCriteria: null })
  );

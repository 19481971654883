import { api, binaryDataApi } from './api';
import {
  AutoRecommendationCategoryId,
  RecommendationViewResponse
} from '@/types/recommendation';
import { BusinessVerticalWithDefault } from '@/enums/businessVertical';

export const getRecommendation = (id: string) =>
  api.get<RecommendationViewResponse>(`/admin/recommendation/${id}`);

export const getRecommendationPDF = (id: string, language: 'en' | 'de') =>
  binaryDataApi.get(`/admin/recommendation/${id}/pdf?language=${language}`);

export interface CreateRecommendationPayload {
  insuranceId?: string;
  locationId: string;
  categoryId?: string;
  /**
   * @deprecated we shouldn't need to pass this, it should be determined by the backend from location
   */
  businessVertical?: BusinessVerticalWithDefault;
}

export const createRecommendation = ({
  insuranceId,
  locationId,
  categoryId,
  businessVertical
}: CreateRecommendationPayload) =>
  api.post('/admin/recommendation', {
    insuranceId,
    locationId,
    categoryId,
    businessVertical
  });

export const createAutoRecommendation = ({
  insuranceId,
  locationId,
  categoryId
}: CreateAutoRecommendationPayload) =>
  api.post('/admin/recommendation/generate', {
    insuranceId,
    locationId,
    categoryId
  });

export const deleteRecommendation = (ids: string[]) =>
  api.delete('/admin/recommendation', { data: { ids } });

export const withdrawRecommendations = (ids: string[]) =>
  api.post('/admin/withdraw-recommendations', {
    ids
  });

export const sendRecommendation = (ids: string[]) =>
  api.post('/admin/send-recommendation', { ids });

export interface CreateAutoRecommendationPayload {
  locationId: string;
  insuranceId: string | null;
  categoryId: AutoRecommendationCategoryId;
}

export interface UpdateRecommendationPayload {
  id: string;
  notes?: string[];
  visibleAttributes?: string[];
  searchCriteria?: string[];
  userSearchCriteria?: string[] | null;
}

export const updateRecommendation = ({
  id,
  ...rest
}: UpdateRecommendationPayload) => api.put(`/admin/recommendation/${id}`, rest);

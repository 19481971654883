import { ReactNode } from 'react';

import { Loader } from '@/components';
import { CarrierLogo } from '@/components/CarrierLogo';
import { PdfCoverNote } from '@/components/PdfCoverNote';
import { Upload } from '@/components/Upload';
import { CategoryLabelMap } from '@/enums';
import { BusinessVertical } from '@/enums/businessVertical';
import { InsuranceLabels, InsuranceTags } from '@/enums/insurance';
import { SendToCarrierModal } from '@/pages/LocationOverview/SendToCarrierModal';
import {
  ValidateModal,
  ValidateModalProps
} from '@/pages/RecommendationPage/ValidateModal';
import {
  useCompany,
  useCreateAutoRecommendation,
  useCreateRecommendation,
  useDeleteInsurance,
  useInsurance,
  useLocationHome,
  usePayments,
  useUpdateInsuranceLabel
} from '@/services';
import { useLocationTypes } from '@/services/locationTypes';
import { Company } from '@/types/company';
import { File } from '@/types/file';
import {
  canShowRequestProtection,
  Insurance,
  InsuranceApiResult,
  isInsuranceValidated,
  isProtectionRequested
} from '@/types/insurance';
import {
  AutoRecommendationCategoryId,
  isAutoRecommendationCategoryId
} from '@/types/recommendation';
import { SelfAssessmentStatus } from '@/types/self-assessment';
import { useGoToPath, useItemDialog, useModal } from '@/utils';
import { ShoppingCartOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Divider, Modal, Popconfirm, Select, Tag } from 'antd';
import { concat, pipe } from 'ramda';
import { useParams } from 'react-router-dom';
import './styles.scss';

interface InsuranceCardProps {
  children?: ReactNode[] | ReactNode;
  categoryId: Insurance['categoryId'] | AutoRecommendationCategoryId;
  locationBusinessVertical: BusinessVertical;
  selfAssessmentStatus: SelfAssessmentStatus;
}

interface BoughtInsuranceCardProps {
  categoryId: Insurance['categoryId'] | AutoRecommendationCategoryId;
  locationBusinessVertical: BusinessVertical;
  name: Insurance['name'];
  carrierName: string;
  logoUrl: string;
  insuranceId: Insurance['insuranceId'];
  insuranceLabel: Insurance['insuranceLabel'];
  consultationProtocol?: File;
  children?: ReactNode[] | ReactNode;
  onEdit: (id: string) => void;
  contractStatus: string | null;
  contractType: string | null;
  policyNumber: string | null;
  risksInsured: string | null;
  mainRiskInsured: string | null;
  allRisksInsured: string[] | null;
  insuredRiskOtherSpecification?: string | null;
  // fixme - second iteration of fixes will tackle this and solving ramda tangle on CompanyPage
  locationData: any;
  selfAssessmentStatus: SelfAssessmentStatus;
  createdAt: string;
  sourceRecommendation?: string;
}

export const InsuranceCard = ({
  categoryId,
  locationBusinessVertical,
  children,
  selfAssessmentStatus
}: InsuranceCardProps) => {
  const { locationId } = useParams<{ locationId: string }>();
  const addRecommendationMutation = useCreateRecommendation({
    locationId,
    categoryId,
    businessVertical: locationBusinessVertical
  });

  const createAutoRecommendationMutation =
    useCreateAutoRecommendation(locationId);

  const handleAddRecommendation = () => addRecommendationMutation.mutate();

  const handleAutoRecommendation = () =>
    createAutoRecommendationMutation.mutate({ categoryId });

  return (
    <div className="insurance-card">
      <div className="insurance-card-body">
        <div className="company-details-info">
          <div className="info-element">
            <span className="label">Category</span>{' '}
            {CategoryLabelMap[categoryId]}
          </div>
        </div>
        <div className="insurance-card-actions">
          <div className="insurance-card-actions-top">
            <Button onClick={handleAddRecommendation}>Add recomm.</Button>
            {selfAssessmentStatus === 'completed' &&
              isAutoRecommendationCategoryId(categoryId) && (
                <Button
                  onClick={handleAutoRecommendation}
                  type="primary"
                  disabled={createAutoRecommendationMutation.isLoading}
                  loading={createAutoRecommendationMutation.isLoading}
                >
                  Auto recomm.
                </Button>
              )}
          </div>
        </div>
      </div>
      {children && <Divider />}
      <div className="recommendation-container">{children}</div>
    </div>
  );
};

const { Option } = Select;

export const BoughtInsuranceCard = ({
  categoryId,
  locationBusinessVertical,
  name,
  carrierName,
  logoUrl,
  insuranceId,
  insuranceLabel,
  consultationProtocol,
  children,
  onEdit,
  contractStatus,
  contractType,
  policyNumber,
  risksInsured,
  mainRiskInsured,
  allRisksInsured,
  insuredRiskOtherSpecification,
  locationData,
  selfAssessmentStatus,
  createdAt,
  sourceRecommendation
}: BoughtInsuranceCardProps) => {
  const { locationId, companyId } = useParams<{
    locationId: string;
    companyId: string;
  }>();

  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();

  const { data: payments = [], isLoading: isPaymentsLoading } = usePayments(
    // TODO: this should be enforced in the props
    companyId
  );
  const { data: company, isLoading: isLoadingCompany } = useCompany(
    // TODO: this should be enforced in the props
    companyId
  );
  const { data: location, isLoading: isLoadingLocation } =
    useLocationHome(locationId);

  const { data: insurance, isLoading: isLoadingInsurance } =
    useInsurance(insuranceId);

  const deleteInsuranceMutation = useDeleteInsurance(insuranceId);
  const updateInsuranceLabelMutation = useUpdateInsuranceLabel(insuranceId);
  const addRecommendationMutation = useCreateRecommendation({
    locationId,
    insuranceId,
    categoryId,
    businessVertical: locationBusinessVertical
  });

  const {
    isOpen,
    openItemDialog,
    closeItemDialog,
    item: selectedItem
  } = useItemDialog<ValidateModalProps['data']>();

  const {
    isOpen: isSendToCarrierModalOpen,
    openItemDialog: openSendToCarrierModal,
    closeItemDialog: closeSendToCarrierModal,
    item: sendToCarrierModalItem
  } = useItemDialog<{
    insurance: InsuranceApiResult;
    company: Company;
  }>();

  const createAutoRecommendationMutation =
    useCreateAutoRecommendation(locationId);

  const goToRecommendation = useGoToPath(
    pipe(String, concat('/recommendation/'))
  );

  const handleDelete = () => deleteInsuranceMutation.mutate();

  const handleUpdateLabel = (label: string) =>
    updateInsuranceLabelMutation.mutate({ insuranceLabel: label });

  const handleAddRecommendation = () => addRecommendationMutation.mutate();

  const handleAutoRecommendation = () =>
    createAutoRecommendationMutation.mutate({ categoryId, insuranceId });

  const handleEdit = () => onEdit(insuranceId);

  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal
  } = useModal();

  const { mainRiskInsuredLabel, allRisksInsuredLabel } = useFormatRiskLabels({
    mainRiskInsured,
    allRisksInsured,
    insuredRiskOtherSpecification
  });

  const shouldShowCoverNote =
    selfAssessmentStatus === 'completed' &&
    ['LI', 'CO', 'LE'].includes(categoryId);

  if (
    isLoadingCompany ||
    isLoadingLocation ||
    isPaymentsLoading ||
    locationTypesLoading
  ) {
    return <Loader />;
  }

  return (
    <>
      <div
        className={`insurance-card ${
          insuranceLabel === InsuranceLabels.important
            ? 'insurance-card-danger'
            : ''
        }`}
      >
        <div className="insurance-card-body">
          <div className="company-details-info">
            <div className="info-element">
              <span className="label">Category</span>{' '}
              {CategoryLabelMap[categoryId]}
            </div>
            {insuranceId && (
              <>
                <div className="info-element">
                  <span className="label">Carrier</span>{' '}
                  <CarrierLogo name={logoUrl} />
                  {carrierName}
                </div>
                <div className="info-element">
                  <span className="label">Tariff</span> {name || '--'}
                </div>
                <div className="info-element">
                  <span className="label">Protocol</span>
                  <Upload
                    buttonText="Upload"
                    file={consultationProtocol}
                    queryKeys={['location', locationId]}
                    belongsTo={insuranceId}
                    type="consultation_protocol"
                    deleteMessage="Are you sure to delete the protocol?"
                  />
                </div>
                {shouldShowCoverNote && (
                  <div className="info-element">
                    <span className="label">Cover Note</span>
                    <Button type="link" onClick={openModal}>
                      See note
                    </Button>
                    <Modal
                      centered
                      open={isModalOpen}
                      onCancel={closeModal}
                      width={1200}
                      footer={false}
                    >
                      <PdfCoverNote
                        categoryId={categoryId}
                        insuranceId={insuranceId}
                        locationData={locationData}
                      />
                    </Modal>
                  </div>
                )}
                <div className="info-element">
                  <span className="label">Policy number</span>{' '}
                  {policyNumber || '--'}
                </div>
                <div className="info-element">
                  <span className="label">Contract status</span>{' '}
                  {contractStatus || '--'}
                </div>
                <div className="info-element">
                  <span className="label">Contract type</span>{' '}
                  {contractType || '--'}
                </div>
                <div className="info-element">
                  <span className="label">Main risk insured</span>{' '}
                  {mainRiskInsuredLabel}
                </div>
                <div className="info-element">
                  <span className="label">Risks insured</span>{' '}
                  {allRisksInsuredLabel}
                </div>
                <div className="info-element">
                  <span className="label">Risks insured (legacy)</span>{' '}
                  {(risksInsured &&
                    locationTypesData?.getLocationLabel(risksInsured)) ||
                    risksInsured ||
                    '--'}
                </div>
              </>
            )}
            {insuranceLabel && (
              <div className="info-element">
                <span className="label">Status</span>{' '}
                <Select
                  onChange={handleUpdateLabel}
                  disabled={!insuranceId}
                  className="insurance-label"
                  defaultValue={insuranceLabel}
                >
                  <Option
                    key={InsuranceLabels.important}
                    value={InsuranceLabels.important}
                  >
                    <Tag color="red">{InsuranceTags.important}</Tag>
                  </Option>
                  <Option
                    key={InsuranceLabels.recommended}
                    value={InsuranceLabels.recommended}
                  >
                    <Tag color="yellow">{InsuranceTags.recommended}</Tag>
                  </Option>
                  <Option
                    key={InsuranceLabels.useful}
                    value={InsuranceLabels.useful}
                  >
                    <Tag color="blue">{InsuranceTags.useful}</Tag>
                  </Option>
                  <Option
                    key={InsuranceLabels.veryGood}
                    value={InsuranceLabels.veryGood}
                  >
                    <Tag color="green">{InsuranceTags.veryGood}</Tag>
                  </Option>
                  <Option
                    key={InsuranceLabels.optimisable}
                    value={InsuranceLabels.optimisable}
                  >
                    <Tag color="blue">{InsuranceTags.optimisable}</Tag>
                  </Option>
                  <Option
                    key={InsuranceLabels.weak}
                    value={InsuranceLabels.weak}
                  >
                    <Tag color="red">{InsuranceTags.weak}</Tag>
                  </Option>
                  <Option
                    key={InsuranceLabels.submitted}
                    value={InsuranceLabels.submitted}
                  >
                    <Tag color="orange">{InsuranceTags.submitted}</Tag>
                  </Option>
                  <Option
                    key={InsuranceLabels.arrears}
                    value={InsuranceLabels.arrears}
                  >
                    <Tag color="red">{InsuranceTags.arrears}</Tag>
                  </Option>
                </Select>
              </div>
            )}
          </div>
          <div className="insurance-card-actions">
            <div className="insurance-card-actions-top">
              <Button onClick={handleAddRecommendation}>Add recomm.</Button>
              {selfAssessmentStatus === 'completed' &&
                isAutoRecommendationCategoryId(categoryId) && (
                  <Button
                    onClick={handleAutoRecommendation}
                    type="primary"
                    disabled={createAutoRecommendationMutation.isLoading}
                    loading={createAutoRecommendationMutation.isLoading}
                  >
                    Auto recomm.
                  </Button>
                )}
              {insuranceId && (
                <>
                  <Button type="primary" onClick={handleEdit}>
                    Edit
                  </Button>
                  <Popconfirm
                    title="Are you sure to delete this insurance?"
                    onConfirm={handleDelete}
                  >
                    <Button danger type="primary">
                      Delete
                    </Button>
                  </Popconfirm>
                </>
              )}
              {canShowRequestProtection(insurance) && (
                <Button
                  type="primary"
                  icon={<ShoppingCartOutlined />}
                  onClick={() => {
                    if (company && location && payments) {
                      openItemDialog({
                        company,
                        location,
                        payments,
                        entity: {
                          type: 'insurance',
                          insuranceId: insuranceId
                        },
                        name: insurance?.name ?? 'Unknown', // this will not happen - more like a safety check
                        startDate: insurance?.startDate,
                        endDate: insurance?.endDate,
                        nextPaymentDate: insurance?.contractPaymentNextDate,
                        agreementNumber: insurance?.agreementNumber,
                        paymentPeriod: insurance?.paymentPeriod,
                        grossPrice: insurance?.grossPrice,
                        carrier: {
                          name: insurance?.carrier?.name ?? 'Unknown', // this will not happen - more like a safety check
                          logoUrl: insurance?.carrier?.logoUrl
                        }
                      });
                    }
                  }}
                >
                  Validate Offer
                </Button>
              )}
              {!isLoadingInsurance &&
                insurance &&
                company &&
                (isInsuranceValidated(insurance) ||
                  isProtectionRequested(insurance)) && (
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    onClick={() =>
                      openSendToCarrierModal({ insurance, company })
                    }
                  >
                    Send to carrier
                  </Button>
                )}
            </div>
            <div>
              {insuranceId && sourceRecommendation && (
                <div
                  onClick={() => goToRecommendation(sourceRecommendation)}
                  className="accepted-recommendation"
                >
                  <p>Accepted recommendation</p>
                  <p>{new Date(createdAt).toLocaleDateString()}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {children && <Divider />}
        <div className="recommendation-container">{children}</div>
      </div>

      {isOpen && selectedItem && (
        <ValidateModal
          visible={isOpen}
          onClose={closeItemDialog}
          data={selectedItem}
        />
      )}

      {isSendToCarrierModalOpen && sendToCarrierModalItem && (
        <SendToCarrierModal
          visible={isSendToCarrierModalOpen}
          onClose={closeSendToCarrierModal}
          data={sendToCarrierModalItem}
        />
      )}
    </>
  );
};

interface FormatRiskLabels {
  mainRiskInsured: string | null;
  allRisksInsured: string[] | null;
  insuredRiskOtherSpecification: Insurance['insuredRiskOtherSpecification'];
}

const useFormatRiskLabels = ({
  mainRiskInsured,
  allRisksInsured,
  insuredRiskOtherSpecification
}: FormatRiskLabels) => {
  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();

  if (locationTypesLoading) {
    return {
      mainRiskInsuredLabel: 'Loading...',
      allRisksInsuredLabel: 'Loading...'
    };
  }

  if (!locationTypesData) {
    return {
      mainRiskInsuredLabel: '- unknown -',
      allRisksInsuredLabel: '- unknown -'
    };
  }

  const formatMainRisk = (
    risk: string | null,
    otherSpecification: Insurance['insuredRiskOtherSpecification']
  ): string => {
    if (risk === 'other' && otherSpecification) {
      return `${locationTypesData.getLocationLabel(risk)} (${otherSpecification})`;
    }

    return risk ? locationTypesData.getLocationLabel(risk) : ' - unknown -';
  };

  const formatAllRisksInsured = (risks: string[] | null): string | null => {
    if (!risks) return null;

    return risks
      .map((risk) => locationTypesData.getLocationLabel(risk))
      .join(', ');
  };

  return {
    mainRiskInsuredLabel: formatMainRisk(
      mainRiskInsured,
      insuredRiskOtherSpecification
    ),
    allRisksInsuredLabel: formatAllRisksInsured(allRisksInsured)
  };
};

import { useState } from 'react';

import { Loader, UpdatableField, Uploader, YesNo } from '@/components';
import { CarrierLogo } from '@/components/CarrierLogo';
import { StarButton } from '@/components/YesNo/StarButton';
import {
  CategoryInsuranceProductConfiguration,
  CategoryLabelMap,
  isDefaultHighlightedAttributes
} from '@/enums';
import { BusinessVerticalWithDefault } from '@/enums/businessVertical';
import {
  useCarriers,
  useCreateInsuranceProduct,
  useInsuranceProduct,
  useUpdateInsuranceProduct
} from '@/services';
import { getOptionsList } from '@/types/productOptions';
import { nonNegativeRule } from '@/utils';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Rate,
  Select,
  Space,
  Switch
} from 'antd';
import { keys, lensPath, set, values } from 'ramda';
import './styles.scss';
import { isAxiosError } from 'axios';
import { isAttributeHighlightingDisabled } from '../RecommendationPage/helpers';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useLocationTypes } from '@/services/locationTypes';
import { useAttributes } from '@/services/attributes';

const { TextArea } = Input;
const { Option } = Select;

interface ProductModalProps {
  insuranceProductId?: string;
  visible: boolean;
  onClose: (val: string | boolean) => void;
  categoryId?: string;
  businessVertical?: BusinessVerticalWithDefault;
  isDuplicated?: boolean;
}

export const ProductModal = ({
  visible,
  onClose,
  categoryId,
  businessVertical,
  insuranceProductId,
  isDuplicated
}: ProductModalProps) => {
  const [form] = Form.useForm();
  const { data: carriers, isLoading: isCarriersLoading } = useCarriers();
  const [isEditingOptionsJSON, setIsEditingOptionsJSON] = useState(false);
  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();
  const { isLoading: isLoadingAttributes, data: attributes } = useAttributes();

  const { data, isLoading } = useInsuranceProduct(
    insuranceProductId as string,
    {
      enabled: Boolean(insuranceProductId)
    }
  );
  const updateInsuranceProductMutation = useUpdateInsuranceProduct(
    insuranceProductId as string
  );

  const createInsuranceProductMutation = useCreateInsuranceProduct();

  if (isLoading || isCarriersLoading || isLoadingAttributes) return <Loader />;

  const onSubmit = () =>
    form.validateFields().then((values) => {
      const mutation =
        insuranceProductId && !isDuplicated
          ? updateInsuranceProductMutation
          : createInsuranceProductMutation;

      mutation
        .mutateAsync(values)
        .then((v) => {
          message.success('Product saved successfully');
          onClose(v.insuranceProductId);
        })
        .catch((e) => {
          if (isAxiosError(e) && e.response?.data?.issues[0]) {
            message.error(e.response?.data?.issues[0]);
            return;
          }

          throw e;
        });
    });

  const updateAttributesFromOptions = (opt: {
    attributes: string[];
    value: boolean;
  }): void => {
    for (const attribute of opt.attributes) {
      form.setFieldValue(['yesNoValues', attribute], opt.value ? 1 : 0);
    }
  };

  return (
    <Modal
      centered
      onOk={onSubmit}
      onCancel={() => onClose(false)}
      okText={
        insuranceProductId ? (isDuplicated ? 'Duplicate' : 'Update') : 'Add'
      }
      title={
        data && insuranceProductId
          ? isDuplicated
            ? `Copy of ${data.name}`
            : 'Update Insurance Product'
          : 'Add Insurance Product'
      }
      closable={false}
      className="add-company-modal"
      open={visible}
      width={830}
    >
      <Form
        scrollToFirstError
        initialValues={
          isDuplicated
            ? { ...data, name: `Copy of ${data && data.name}` }
            : data
        }
        layout="vertical"
        form={form}
        style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}
      >
        <Form.Item
          label="Product"
          name="categoryId"
          initialValue={categoryId || data?.categoryId}
          rules={[{ required: true, message: 'Required!' }]}
        >
          <Select
            disabled={!!categoryId}
            showSearch
            filterOption={(inputValue, option) =>
              (option?.label ?? '')
                ?.toUpperCase()
                .startsWith(inputValue?.toUpperCase())
            }
            options={keys(CategoryLabelMap)
              .sort((a, b) =>
                CategoryLabelMap[a].localeCompare(CategoryLabelMap[b])
              )
              .map((item) => ({
                value: item,
                label: CategoryLabelMap[item]
              }))}
          />
        </Form.Item>
        <UpdatableField>
          {() => {
            const categoryId = form.getFieldValue('categoryId');

            if (categoryId && CategoryLabelMap[categoryId])
              return (
                <Form.Item
                  label="Vertical"
                  name="businessVertical"
                  initialValue={businessVertical || data?.businessVertical}
                  rules={[{ required: true, message: 'Required!' }]}
                >
                  <Select
                    options={Object.keys(
                      // TODO: attributes
                      CategoryInsuranceProductConfiguration[categoryId]
                    ).map((item) => {
                      return {
                        value: item,
                        label: locationTypesLoading
                          ? 'Loading...'
                          : locationTypesData?.getVerticalLabel(item) ||
                            '- Default -'
                      };
                    })}
                  />
                </Form.Item>
              );

            return (
              <Form.Item
                label="Vertical"
                name="businessVertical"
                rules={[{ required: true, message: 'Required!' }]}
              >
                <Select disabled options={[]} />
              </Form.Item>
            );
          }}
        </UpdatableField>
        <Form.Item
          label="Carrier"
          name="carrierId"
          rules={[{ required: true, message: 'Required!' }]}
        >
          <Select
            showSearch
            filterOption={(inputValue, option) =>
              option?.children?.[1]
                ?.toUpperCase()
                .startsWith(inputValue?.toUpperCase())
            }
          >
            {carriers &&
              carriers
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({ name, logoUrl, carrierId }) => (
                  <Option key={carrierId} value={carrierId}>
                    <CarrierLogo name={logoUrl} />
                    {name}
                  </Option>
                ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Product Coverage Rating"
          name="contractCoverageRating"
          rules={[{ required: true, message: 'Required!' }]}
        >
          <Rate />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: 'Required!' }]}
        >
          <TextArea autoSize={{ minRows: 6 }} />
        </Form.Item>
        <Form.Item
          label="Tariff (product name)"
          name="name"
          rules={[{ required: true, message: 'Required!' }]}
        >
          <Input />
        </Form.Item>

        <UpdatableField>
          {() => {
            const categoryId = form.getFieldValue('categoryId');
            if (categoryId === 'LI') {
              if (![0, 250, 500].includes(form.getFieldValue('deductible'))) {
                form.setFieldValue('deductible', 0);
              }

              return (
                <Form.Item
                  initialValue={0}
                  label="Deductible"
                  name="deductible"
                  rules={[{ required: true, message: 'Required!' }]}
                >
                  <Select>
                    <Option key={0} value={0}>
                      €0
                    </Option>
                    <Option key={250} value={250}>
                      €250
                    </Option>
                    <Option key={500} value={500}>
                      €500
                    </Option>
                  </Select>
                </Form.Item>
              );
            } else {
              return (
                <Form.Item
                  initialValue={0}
                  label="Deductible"
                  name="deductible"
                  rules={[
                    { required: true, message: 'Required!' },
                    nonNegativeRule
                  ]}
                >
                  <Input type="number" addonBefore="€" />
                </Form.Item>
              );
            }
          }}
        </UpdatableField>
        <UpdatableField>
          <Form.Item
            initialValue={0}
            label="Commission Rate"
            name="commission"
            rules={[{ required: true, message: 'Required!' }, nonNegativeRule]}
          >
            <Input type="number" addonBefore="%" />
          </Form.Item>
        </UpdatableField>

        <UpdatableField>
          <Form.Item label="RaVe-Nummer" name="agreementNumber">
            <Input placeholder="1012852" />
          </Form.Item>
        </UpdatableField>

        <UpdatableField>
          <Form.Item
            label="Multi-risk capable"
            name="multiRiskAllowed"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </UpdatableField>

        <Divider orientation="left" plain>
          <Space>
            <span>Options</span>
            <Button
              type="text"
              onClick={() => {
                setIsEditingOptionsJSON(!isEditingOptionsJSON);
              }}
            >
              {isEditingOptionsJSON ? <CloseOutlined /> : <EditOutlined />}
            </Button>
          </Space>
        </Divider>

        {isEditingOptionsJSON ? (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Alert
              message="This is advanced option. Please do not use if you don't know how this works"
              type="warning"
              showIcon
            />
            <Alert
              message="Editing is not possible yet. Paste the whole valid object"
              type="info"
              showIcon
            />
            <UpdatableField>
              <Form.Item
                label="JSON value"
                name="options"
                shouldUpdate
                // below is kinda hacky
                // it also prevents to edit JSON in place - the only way to use it is to paste the whole valid JSON
                // in the future we will enable standard editing of each item
                getValueFromEvent={(evt) => JSON.parse(evt.target.value)}
                getValueProps={(v) => ({ value: JSON.stringify(v, null, 2) })}
              >
                <TextArea
                  autoSize={{ minRows: 6 }}
                  onChange={(evt) => {
                    try {
                      const v = JSON.parse(evt.target.value);
                      for (const opt of values(v)) {
                        updateAttributesFromOptions(opt);
                      }
                    } catch (e) {
                      console.log('JSON not valid');
                    }
                  }}
                />
              </Form.Item>
            </UpdatableField>
          </Space>
        ) : (
          <Form.Item name="options">
            <Space direction="vertical" align="start">
              {data?.options &&
                getOptionsList(data.options).map(
                  ({ name, label, attributes }) => (
                    <Space key={name} direction="horizontal" align="baseline">
                      <Form.Item
                        name={['options', name, 'value']}
                        valuePropName="checked"
                        style={{ margin: '0' }}
                      >
                        <Switch
                          onChange={(value) => {
                            const currentState = form.getFieldValue('options');
                            const newState = set(
                              lensPath([name, 'value']),
                              value,
                              currentState
                            );
                            form.setFieldValue('options', newState);
                            updateAttributesFromOptions({ attributes, value });
                          }}
                        />
                      </Form.Item>
                      {/* we use plain label here because in vertical form it's not possible to have some fields with horizontal layout*/}
                      <label htmlFor={['options', name, 'value'].join('_')}>
                        {label}
                      </label>
                    </Space>
                  )
                )}
            </Space>
          </Form.Item>
        )}

        <Divider orientation="left" plain>
          Attributes
        </Divider>

        <UpdatableField>
          {() => {
            const categoryId = form.getFieldValue('categoryId');
            const businessVertical = form.getFieldValue('businessVertical');

            if (
              categoryId &&
              businessVertical &&
              // TODO: attributes
              CategoryInsuranceProductConfiguration[categoryId]?.[
                businessVertical as BusinessVerticalWithDefault
              ]?.parametersValues
            )
              return (
                <div className="gridContainer">
                  <div className="attributeContainer">
                    <span>Attributes</span>
                    <span>German</span>
                    <span>English</span>
                    <span>Yes/No</span>
                    <span>Highlight</span>
                    <span>Highlight</span>
                  </div>
                  {/* TODO: attributes */}
                  {CategoryInsuranceProductConfiguration[categoryId][
                    businessVertical as BusinessVerticalWithDefault
                  ]?.parametersValues.map(({ name }, index) => (
                    <div
                      className="attributeContainer"
                      key={`${name}-${index}`}
                    >
                      <i className="parameter-value-label">
                        {attributes?.getAttributeLabel(name)}
                      </i>
                      <Form.Item name={['parametersValues', 'de', name]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={['parametersValues', 'en', name]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={['yesNoValues', name]}>
                        <YesNo />
                      </Form.Item>
                      <Form.Item
                        name={['highlightedAttributes', name]}
                        initialValue={isDefaultHighlightedAttributes(
                          categoryId,
                          businessVertical,
                          name
                        )}
                      >
                        <StarButton
                          disabled={isAttributeHighlightingDisabled(
                            form.getFieldValue('highlightedAttributes'),
                            name
                          )}
                        />
                      </Form.Item>
                    </div>
                  ))}
                </div>
              );

            return null;
          }}
        </UpdatableField>
        {insuranceProductId && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              zIndex: 10000000
            }}
          >
            <Uploader
              max={10}
              files={(data && data.insuranceProductFiles) || []}
              queryKeys={['insuranceProduct', insuranceProductId]}
              belongsTo={insuranceProductId}
              type="insurance_product"
              extended
              category="contract"
            />
          </div>
        )}
      </Form>
    </Modal>
  );
};

import { BusinessVertical } from '@/enums/businessVertical';

interface InsuranceProductTemplate {
  parametersValues: {
    name: string;
  }[];
}

// default configuration, plus optional configuration for each business vertical
type InsuranceProductConfiguration = Record<
  'default',
  InsuranceProductTemplate
> &
  Partial<Record<BusinessVertical, InsuranceProductTemplate>>;

type CategoryInsuranceProductConfiguration = Record<
  string,
  InsuranceProductConfiguration
>;

export const CategoryLabelMap: Record<string, string> = {
  LI: 'Liability insurance',
  CO: 'Contents insurance',
  LE: 'Legal insurance',
  CY: 'Cyber insurance',
  TR: 'Transport insurance',
  CA: 'Car insurance',
  BU: 'Building insurance',
  RE: 'Rent deposit insurance',
  HE: 'Company health insurance',
  PE: 'Company pension plan',
  EL: 'Electronics insurance',
  DO: 'D&O insurance',
  DI: 'Direct insurance',
  CC: 'Car contents insurance',
  GL: 'Glass insurance',
  HL: 'Home- and landowners liability insurance',
  HO: 'Homeowners insurance',
  TL: 'Term life insurance',
  AI: 'Accident insurance',
  EV: 'Event liability Insurance',
  FL: 'Financial loss liability insurance',
  PF: 'Professional liability insurance',
  BS: 'Business closure insurance',
  AL: 'Multi-peril insurance/multiline',
  CL: 'Club liability insurance',
  BP: 'Basic pension (unit-linked)',
  TH: 'Travel health insurance',
  EX: 'Exhibition insurance',
  BL: "Builder's liability insurance",
  PR: 'Building performance insurance',
  DS: 'Disability insurance',
  IN: 'Business interruption insurance',
  CR: 'Carrier liability insurance',
  DB: 'Daily benefits insurance',
  HI: 'Health insurance',
  SH: 'Statutory health insurance',
  CH: 'Comprehensive health insurance',
  SU: 'Supplementary health insurance',
  MI: 'Machinery insurance',
  LC: 'Long-term care insurance',
  PL: 'Personal liability insurance',
  TC: 'Travel cancellation insurance',
  AN: 'Annuity insurance (unit-linked)',
  RP: 'Riester pension (unit-linked)',
  AO: 'Animal owner liability insurance',
  AH: 'Animal health insurance',
  LF: 'Life insurance (unit-linked)',
  OD: 'Occupational disability insurance',
  WD: 'Water damage liability insurance',
  BC: 'Basic pension (classic)',
  SV: 'Building savings contract',
  OL: "Operator's liability insurance",
  LP: 'Loss of profit insurance',
  CP: 'Camping insurance',
  LO: 'Loan contract',
  EM: "Employer's liability insurance",
  DR: 'Dread disease',
  FU: 'Funds',
  CM: 'Camera insurance',
  WA: 'Warranty insurance',
  BA: 'Basic Abilities Insurance',
  HU: 'Hunting liability insurance',
  DH: 'Daily hospital allowance insurance',
  AR: 'Art insurance',
  SP: 'Spa costs insurance/curative daily allowance',
  LU: 'Life insurance (different from Lebensversicherung (fondsgebunden))',
  LL: 'life insurance (classic)',
  RL: 'Rent loss insurance',
  MU: 'Music instrument insurance',
  NC: 'Nursing care insurance',
  DN: 'Daily nursing allowance insurance',
  BG: 'Baggage insurance',
  PC: 'Pension plan (classic)',
  ST: 'Statutory pension insurance',
  RI: 'Riester pension (classic)',
  PO: 'Protective cover insurance',
  ML: 'Marine liability insurance',
  OT: 'Other lines',
  SB: 'Sport boat hull insurance',
  DE: 'Death benefit insurance',
  TE: 'Technical insurance',
  AP: 'Accident insurance with premium refund',
  AM: 'Accident insurance/multi-risk policy',
  TA: 'Traffic liability insurance',
  PP: 'Pension plan'
};

// TODO: remove (at least translations)
export const CategoryInsuranceProductConfiguration: CategoryInsuranceProductConfiguration =
  {
    LI: {
      default: {
        parametersValues: [
          {
            name: 'productLiabilityCoverage'
          },
          {
            name: 'rentalPropertyDamageToRoomsAndBuildings'
          },
          {
            name: 'environmentalDamageInsurance'
          },
          {
            name: 'violationOfDataProtectionLaws'
          },
          {
            name: 'lossOfThirdPartyKeys'
          },
          {
            name: 'discriminationOrHarassment'
          },
          { name: 'improvementGuarantee' },
          {
            name: 'productLiabilityExtended'
          },
          {
            name: 'grossNegligenceObligations'
          },
          {
            name: 'grossNegligenceClaims'
          },
          {
            name: 'coverageAmountPersonalInjury'
          },
          {
            name: 'coverageAmountPropertyDamage'
          },
          {
            name: 'coverageAmountFinancialLosses'
          },
          {
            name: 'lossOfPropertyOfGuestsPatients'
          },
          { name: 'custodyRisk' },
          {
            name: 'homeownerAndLandownerLiability'
          },
          {
            name: 'performanceUpdateGuarantee'
          },
          {
            name: 'shipmentCateringAndDeliveryService'
          },
          {
            name: 'personalLiabilityInsurance'
          },
          {
            name: 'sumAndConditionDifferenceCoverage'
          },
          {
            name: 'infringementOfPersonalAndNameRights'
          }
        ]
      },
      craftwork: {
        parametersValues: [
          {
            name: 'activityRelatedDamages'
          },
          {
            name: 'costsRemediationOfDefects'
          },
          {
            name: 'rectificationAccompanyingDamages'
          },
          {
            name: 'comissioningOfSubcontractors'
          },
          {
            name: 'costOfDefectRectification'
          },
          {
            name: 'coverageAmountPersonalInjury'
          },
          {
            name: 'coverageAmountPropertyDamage'
          },
          {
            name: 'coverageAmountFinancialLosses'
          },
          {
            name: 'rentalPropertyDamageToRoomsAndBuildings'
          },
          {
            name: 'lossOfThirdPartyKeys'
          },
          {
            name: 'improvementGuarantee'
          },
          {
            name: 'grossNegligenceObligations'
          },
          {
            name: 'grossNegligenceClaims'
          },
          {
            name: 'performanceUpdateGuarantee'
          },
          {
            name: 'personalLiabilityInsurance'
          },
          {
            name: 'productLiabilityExtended'
          },
          {
            name: 'environmentalDamageInsurance'
          },
          {
            name: 'discriminationOrHarassment'
          },
          {
            name: 'violationOfDataProtectionLaws'
          },
          {
            name: 'infringementOfPersonalAndNameRights'
          },
          {
            name: 'additionalCoverageInAccordanceWithAKB'
          }
        ]
      },
      construction: {
        parametersValues: [
          {
            name: 'activityRelatedDamages'
          },
          {
            name: 'costsRemediationOfDefects'
          },
          {
            name: 'rectificationAccompanyingDamages'
          },
          {
            name: 'comissioningOfSubcontractors'
          },
          {
            name: 'costOfDefectRectification'
          },
          {
            name: 'coverageAmountPersonalInjury'
          },
          {
            name: 'coverageAmountPropertyDamage'
          },
          {
            name: 'coverageAmountFinancialLosses'
          },
          {
            name: 'rentalPropertyDamageToRoomsAndBuildings'
          },
          {
            name: 'lossOfThirdPartyKeys'
          },
          {
            name: 'improvementGuarantee'
          },
          {
            name: 'grossNegligenceObligations'
          },
          {
            name: 'grossNegligenceClaims'
          },
          {
            name: 'performanceUpdateGuarantee'
          },
          {
            name: 'personalLiabilityInsurance'
          },
          {
            name: 'productLiabilityExtended'
          },
          {
            name: 'environmentalDamageInsurance'
          },
          {
            name: 'discriminationOrHarassment'
          },
          {
            name: 'violationOfDataProtectionLaws'
          },
          {
            name: 'infringementOfPersonalAndNameRights'
          },
          {
            name: 'additionalCoverageInAccordanceWithAKB'
          }
        ]
      },
      ecommerceAndRetail: {
        parametersValues: [
          {
            name: 'productLiabilityCoverage'
          },
          {
            name: 'rentalPropertyDamageToRoomsAndBuildings'
          },
          {
            name: 'environmentalDamageInsurance'
          },
          {
            name: 'violationOfDataProtectionLaws'
          },
          {
            name: 'lossOfThirdPartyKeys'
          },
          {
            name: 'discriminationOrHarassment'
          },
          { name: 'improvementGuarantee' },
          {
            name: 'productLiabilityExtended'
          },
          {
            name: 'grossNegligenceObligations'
          },
          {
            name: 'grossNegligenceClaims'
          },
          {
            name: 'coverageAmountPersonalInjury'
          },
          {
            name: 'coverageAmountPropertyDamage'
          },
          {
            name: 'coverageAmountFinancialLosses'
          },
          {
            name: 'lossOfPropertyOfGuestsPatients'
          },
          { name: 'custodyRisk' },
          {
            name: 'homeownerAndLandownerLiability'
          },
          {
            name: 'performanceUpdateGuarantee'
          },
          {
            name: 'shipmentCateringAndDeliveryService'
          },
          {
            name: 'personalLiabilityInsurance'
          },
          {
            name: 'sumAndConditionDifferenceCoverage'
          },
          {
            name: 'infringementOfPersonalAndNameRights'
          },
          {
            name: 'liabilityFinancialLossCoverage'
          },
          {
            name: 'liabilityAmazonBusinessCoverage'
          },
          {
            name: 'liabilityExportsToUsa'
          }
        ]
      }
    },
    CO: {
      default: {
        parametersValues: [
          { name: 'glassBreakage' },
          { name: 'elementary' },
          { name: 'eCHazards' },
          { name: 'unnamedPerils' },
          { name: 'flooding' },
          { name: 'electronics' },
          { name: 'mobileDangers' },
          { name: 'closingCosts' },
          { name: 'plusBaustein2' },
          {
            name: 'basicRisks'
          },
          {
            name: 'lossOfEarnings'
          },
          {
            name: 'outdoorPropertyTheft'
          },
          {
            name: 'outdoorPropertyStorm'
          },
          {
            name: 'companySignsAdvertisingSystems'
          },
          {
            name: 'cashOutsideSafe'
          },
          {
            name: 'refrigeratedAndFrozenGoods'
          },
          { name: 'surgeDamage' },
          {
            name: 'underinsuranceWaiver'
          },
          {
            name: 'carContainmentTransport'
          },
          {
            name: 'grossNegligenceObligations'
          },
          { name: 'grossNegligenceClaim' },
          {
            name: 'newValueCoverage'
          },
          {
            name: 'scorchingDamage'
          }
        ]
      },
      gastronomy: {
        parametersValues: [
          { name: 'glassBreakage' },
          { name: 'elementary' },
          { name: 'eCHazards' },
          { name: 'unnamedPerils' },
          { name: 'flooding' },
          { name: 'electronics' },
          { name: 'mobileDangers' },
          { name: 'closingCosts' },
          { name: 'plusBaustein2' },
          {
            name: 'basicRisks'
          },
          {
            name: 'lossOfEarnings'
          },
          {
            name: 'outdoorPropertyTheft'
          },
          {
            name: 'outdoorPropertyStorm'
          },
          {
            name: 'companySignsAdvertisingSystems'
          },
          {
            name: 'cashOutsideSafe'
          },
          {
            name: 'refrigeratedAndFrozenGoods'
          },
          { name: 'surgeDamage' },
          {
            name: 'underinsuranceWaiver'
          },
          {
            name: 'carContainmentTransport'
          },
          {
            name: 'grossNegligenceObligations'
          },
          { name: 'grossNegligenceClaim' },
          {
            name: 'newValueCoverage'
          },
          {
            name: 'scorchingDamage'
          }
        ]
      }
    },
    LE: {
      default: {
        parametersValues: [
          {
            name: 'companyCoverage'
          },
          {
            name: 'employerLaborLawProtectionArea'
          },
          {
            name: 'realEstateCoverage'
          },
          {
            name: 'trafficCoverage'
          },
          {
            name: 'specialCriminalLegalProtectionInsurance'
          },
          {
            name: 'corporateContractLegalProtectionInsurance'
          },
          {
            name: 'privateCoverage'
          },
          {
            name: 'claimsManagementCollectionLegalProtection'
          },
          {
            name: 'lawyerHotline'
          },
          {
            name: 'freeChoiceOfLawyer'
          },
          {
            name: 'scopeOfApplication'
          },
          {
            name: 'mediation'
          },
          {
            name: 'reputationProtection'
          },
          {
            name: 'sumInsuredAbroad'
          },
          {
            name: 'sumInsuredCriminalBail'
          },
          {
            name: 'waiverOfDeductibleForCaseClosingInitialConsultation'
          },
          {
            name: 'waitingPeriod'
          },
          {
            name: 'copyrightCompetitionLaw'
          },
          {
            name: 'contractLegalProtection'
          },
          {
            name: 'insuranceContractLegalProtection'
          }
        ]
      }
    },
    CY: {
      default: {
        parametersValues: [
          {
            name: 'cyberLossOfEarningsOnPremises'
          },
          {
            name: 'cyberLossOfEarningsCloud'
          },
          {
            name: 'cyberFraudAndBurglary'
          },
          {
            name: 'cyberPayments'
          },
          {
            name: 'cyberTrustDamage'
          },
          {
            name: 'cyberLiability'
          },
          {
            name: 'cyberPreventionPremium'
          },
          {
            name: 'fines'
          },
          {
            name: 'cyberTheft'
          },
          {
            name: 'forensicsCosts'
          },
          {
            name: 'scope'
          },
          {
            name: 'hotlineCrisis'
          },
          {
            name: 'businessInterruptionCosts'
          },
          {
            name: 'lateRegistrationDeadline'
          },
          {
            name: 'reverseCoverage'
          },
          {
            name: 'breachOfConfidentialityPenalties'
          },
          {
            name: 'currentAntivirus'
          },
          {
            name: 'operatingErrors'
          },
          {
            name: 'cyberTraining'
          },
          {
            name: 'dataViolation'
          },
          {
            name: 'denialOfService'
          },
          {
            name: 'hackerAttacks'
          },
          {
            name: 'crisisPlan'
          },
          {
            name: 'malware'
          },
          {
            name: 'immediateHelp'
          },
          {
            name: 'accessControlsRequired'
          }
        ]
      }
    },
    TR: {
      default: {
        parametersValues: []
      }
    },
    CA: {
      default: {
        parametersValues: []
      }
    },
    BU: {
      default: {
        parametersValues: []
      }
    },
    RE: {
      default: {
        parametersValues: []
      }
    },
    HE: {
      default: {
        parametersValues: [
          { name: 'oneOrTwoBedrooms' },
          { name: 'budgetTariff' },
          { name: 'healthCheck' },
          { name: 'dailyHospital' },
          { name: 'dailySickness' },
          { name: 'dailySpa' },
          { name: 'toothCleaning' },
          { name: 'advancePayment' },
          { name: 'preventiveExaminations' },
          { name: 'dentures' },
          { name: 'medicationAndDressings' },
          {
            name: 'chiefPhysicianTreatment'
          },
          { name: 'insurableFamilyMembers' },
          { name: 'remedies' },
          {
            name: 'alternativePractitioner'
          },
          { name: 'aids' },
          { name: 'plasticFillings' },
          { name: 'vaccinations' },
          { name: 'visualAids' },
          { name: 'preventiveCareVouchers' }
        ]
      }
    },
    PE: {
      default: {
        parametersValues: []
      }
    },
    EL: {
      default: {
        parametersValues: [
          {
            name: 'externalDamages'
          },
          {
            name: 'cleanupCosts'
          },
          {
            name: 'movementAndProtectionCosts'
          },
          { name: 'dataInsurance' },
          {
            name: 'lossOfEarnings'
          },
          {
            name: 'additionalCostsInsurance'
          },
          {
            name: 'mobileUsedItems'
          },
          {
            name: 'softwareProtectionModules'
          },
          { name: 'softwareInsurance' },
          { name: 'underinsuranceWaiver' },
          { name: 'precautionaryInsurance' },
          {
            name: 'assetGroup1'
          },
          {
            name: 'assetGroup2'
          },
          {
            name: 'assetGroup3'
          },
          {
            name: 'assetGroup4'
          },
          { name: 'assetGroup5' },
          {
            name: 'exclusionBurglary'
          },
          { name: 'exclusionFire' },
          { name: 'exclusionTapWater' },
          {
            name: 'grossNegligenceObligations'
          },
          {
            name: 'grossNegligenceClaims'
          }
        ]
      },
      marketingAndCreative: {
        parametersValues: [
          {
            name: 'externalDamages'
          },
          {
            name: 'cleanupCosts'
          },
          {
            name: 'movementAndProtectionCosts'
          },
          { name: 'dataInsurance' },
          {
            name: 'lossOfEarnings'
          },
          {
            name: 'additionalCostsInsurance'
          },
          {
            name: 'mobileUsedItems'
          },
          {
            name: 'softwareProtectionModules'
          },
          { name: 'softwareInsurance' },
          { name: 'underinsuranceWaiver' },
          { name: 'precautionaryInsurance' },
          {
            name: 'assetGroup1'
          },
          {
            name: 'assetGroup2'
          },
          {
            name: 'assetGroup3'
          },
          {
            name: 'assetGroup4'
          },
          { name: 'assetGroup5' },
          {
            name: 'exclusionBurglary'
          },
          { name: 'exclusionFire' },
          { name: 'exclusionTapWater' },
          {
            name: 'grossNegligenceObligations'
          },
          {
            name: 'grossNegligenceClaims'
          }
        ]
      },
      medicalProfessions: {
        parametersValues: [
          {
            name: 'externalDamages'
          },
          {
            name: 'cleanupCosts'
          },
          {
            name: 'movementAndProtectionCosts'
          },
          { name: 'dataInsurance' },
          {
            name: 'lossOfEarnings'
          },
          {
            name: 'additionalCostsInsurance'
          },
          {
            name: 'mobileUsedItems'
          },
          {
            name: 'softwareProtectionModules'
          },
          { name: 'softwareInsurance' },
          { name: 'underinsuranceWaiver' },
          { name: 'precautionaryInsurance' },
          {
            name: 'assetGroup1'
          },
          {
            name: 'assetGroup2'
          },
          {
            name: 'assetGroup3'
          },
          {
            name: 'assetGroup4'
          },
          { name: 'assetGroup5' },
          {
            name: 'exclusionBurglary'
          },
          { name: 'exclusionFire' },
          { name: 'exclusionTapWater' },
          {
            name: 'grossNegligenceObligations'
          },
          {
            name: 'grossNegligenceClaims'
          }
        ]
      },
      therapist: {
        parametersValues: [
          {
            name: 'externalDamages'
          },
          {
            name: 'cleanupCosts'
          },
          {
            name: 'movementAndProtectionCosts'
          },
          { name: 'dataInsurance' },
          {
            name: 'lossOfEarnings'
          },
          {
            name: 'additionalCostsInsurance'
          },
          {
            name: 'mobileUsedItems'
          },
          {
            name: 'softwareProtectionModules'
          },
          { name: 'softwareInsurance' },
          { name: 'underinsuranceWaiver' },
          { name: 'precautionaryInsurance' },
          {
            name: 'assetGroup1'
          },
          {
            name: 'assetGroup2'
          },
          {
            name: 'assetGroup3'
          },
          {
            name: 'assetGroup4'
          },
          { name: 'assetGroup5' },
          {
            name: 'exclusionBurglary'
          },
          { name: 'exclusionFire' },
          { name: 'exclusionTapWater' },
          {
            name: 'grossNegligenceObligations'
          },
          {
            name: 'grossNegligenceClaims'
          }
        ]
      },
      vehicleTradingAndMaintenance: {
        parametersValues: [
          { name: 'externalDamages' },
          { name: 'cleanupCosts' },
          { name: 'movementAndProtectionCosts' },
          { name: 'dataInsurance' },
          { name: 'lossOfEarnings' },
          { name: 'additionalCostsInsurance' },
          { name: 'mobileUsedItems' },
          { name: 'softwareProtectionModules' },
          { name: 'softwareInsurance' },
          { name: 'underinsuranceWaiver' },
          { name: 'precautionaryInsurance' },
          { name: 'assetGroup1' },
          { name: 'assetGroup2' },
          { name: 'assetGroup3' },
          { name: 'assetGroup4' },
          { name: 'assetGroup5' },
          { name: 'exclusionBurglary' },
          { name: 'exclusionFire' },
          { name: 'exclusionTapWater' },
          { name: 'grossNegligenceObligations' },
          { name: 'grossNegligenceClaims' }
        ]
      },
      gastronomy: {
        parametersValues: [
          { name: 'externalDamages' },
          { name: 'cleanupCosts' },
          { name: 'movementAndProtectionCosts' },
          { name: 'dataInsurance' },
          { name: 'lossOfEarnings' },
          { name: 'additionalCostsInsurance' },
          { name: 'mobileUsedItems' },
          { name: 'softwareProtectionModules' },
          { name: 'softwareInsurance' },
          { name: 'underinsuranceWaiver' },
          { name: 'precautionaryInsurance' },
          { name: 'assetGroup1' },
          { name: 'assetGroup2' },
          { name: 'assetGroup3' },
          { name: 'assetGroup4' },
          { name: 'assetGroup5' },
          { name: 'exclusionBurglary' },
          { name: 'exclusionFire' },
          { name: 'exclusionTapWater' },
          { name: 'grossNegligenceObligations' },
          { name: 'grossNegligenceClaims' }
        ]
      }
    },
    DO: {
      default: {
        parametersValues: [
          { name: 'claimsMadePrinciple' },
          { name: 'reputationalDamageCosts' },
          { name: 'lateNotificationPeriod' },
          { name: 'retroactiveCoverage' },
          { name: 'preContractualBreach' },
          { name: 'cancellationEventOfClaim' },
          { name: 'scopeAllocation' },
          { name: 'defenseCostsInjury' },
          { name: 'defenseCostsAdditional' },
          { name: 'selfDamageCoverage' },
          { name: 'freeChoiceAttorney' },
          { name: 'managingOrgans' },
          { name: 'noAutomaticTerminationInsolvency' },
          { name: 'costsConflictManager' },
          { name: 'annualMaximization' },
          { name: 'breachOfDuty' },
          { name: 'authorizedSignatories' },
          { name: 'arbitration' },
          { name: 'infringementPrinciple' },
          { name: 'replenishmentSumInsured' }
        ]
      }
    },
    DI: {
      default: {
        parametersValues: [
          { name: 'insuredPerson' },
          { name: 'occupationalDisability' },
          { name: 'pensionGuaranteePeriod' },
          { name: 'careOption' },
          { name: 'deathBeforeAnnuity' },
          { name: 'premiumGuaranteeAtExpiry' },
          { name: 'finalAge' }
        ]
      }
    },
    CC: {
      default: {
        parametersValues: []
      }
    },
    GL: {
      default: {
        parametersValues: []
      }
    },
    HL: {
      default: {
        parametersValues: []
      }
    },
    HO: {
      default: {
        parametersValues: []
      }
    },
    TL: {
      default: {
        parametersValues: []
      }
    },
    AI: {
      default: {
        parametersValues: []
      }
    },
    EV: {
      default: {
        parametersValues: []
      }
    },
    FL: {
      default: {
        parametersValues: [
          { name: 'liabilityInsurance' },
          { name: 'personalDamage' },
          { name: 'printDamage' },
          { name: 'damageToTrust' },
          { name: 'damageToReputation' },
          { name: 'lossOfDocuments' },
          { name: 'criminalDefenseCosts' },
          { name: 'passiveLegalProtection' },
          { name: 'violationOfDataProtection' },
          { name: 'violationOfIndustrialPropertyRights' },
          { name: 'breachOfConfidentiality' },
          { name: 'riskOfCommissioning' },
          { name: 'coInsuranceOfFreelancers' },
          { name: 'claimsOfDamageFromThirdParties' },
          { name: 'spatialScope' },
          { name: 'missingADeadline' },
          { name: 'falseAdvice' },
          { name: 'keyManCover' },
          { name: 'reimbursementProjectCosts' }
        ]
      },
      startUpAndItServices: {
        parametersValues: [
          { name: 'liabilityInsurance' },
          { name: 'personalDamage' },
          { name: 'printDamage' },
          { name: 'damageToTrust' },
          { name: 'damageToReputation' },
          { name: 'lossOfDocuments' },
          { name: 'criminalDefenseCosts' },
          { name: 'passiveLegalProtection' },
          { name: 'violationOfDataProtection' },
          { name: 'violationOfIndustrialPropertyRights' },
          { name: 'breachOfConfidentiality' },
          { name: 'riskOfCommissioning' },
          { name: 'coInsuranceOfFreelancers' },
          { name: 'claimsOfDamageFromThirdParties' },
          { name: 'spatialScope' },
          { name: 'missingADeadline' },
          { name: 'falseAdvice' },
          { name: 'keyManCover' },
          { name: 'reimbursementProjectCosts' }
        ]
      },
      consultingBusinessType: {
        parametersValues: [
          { name: 'liabilityInsurance' },
          { name: 'personalDamage' },
          { name: 'printDamage' },
          { name: 'damageToTrust' },
          { name: 'damageToReputation' },
          { name: 'lossOfDocuments' },
          { name: 'criminalDefenseCosts' },
          { name: 'passiveLegalProtection' },
          { name: 'violationOfDataProtection' },
          { name: 'violationOfIndustrialPropertyRights' },
          { name: 'breachOfConfidentiality' },
          { name: 'riskOfCommissioning' },
          { name: 'coInsuranceOfFreelancers' },
          { name: 'claimsOfDamageFromThirdParties' },
          { name: 'spatialScope' },
          { name: 'missingADeadline' },
          { name: 'falseAdvice' },
          { name: 'keyManCover' },
          { name: 'reimbursementProjectCosts' }
        ]
      },
      marketingAndCreative: {
        parametersValues: [
          { name: 'liabilityInsurance' },
          { name: 'personalDamage' },
          { name: 'printDamage' },
          { name: 'damageToTrust' },
          { name: 'damageToReputation' },
          { name: 'lossOfDocuments' },
          { name: 'criminalDefenseCosts' },
          { name: 'passiveLegalProtection' },
          { name: 'violationOfDataProtection' },
          { name: 'violationOfIndustrialPropertyRights' },
          { name: 'breachOfConfidentiality' },
          { name: 'riskOfCommissioning' },
          { name: 'coInsuranceOfFreelancers' },
          { name: 'claimsOfDamageFromThirdParties' },
          { name: 'spatialScope' },
          { name: 'missingADeadline' },
          { name: 'falseAdvice' },
          { name: 'keyManCover' },
          { name: 'reimbursementProjectCosts' }
        ]
      }
    },
    PF: {
      default: {
        parametersValues: []
      }
    },
    BS: {
      default: {
        parametersValues: []
      }
    },
    AL: {
      default: {
        parametersValues: []
      }
    },
    CL: {
      default: {
        parametersValues: []
      }
    },
    BP: {
      default: {
        parametersValues: [
          { name: 'insuredPerson' },
          { name: 'occupationalDisability' },
          { name: 'pensionGuaranteePeriod' },
          { name: 'careOption' },
          { name: 'deathBeforeAnnuity' },
          { name: 'premiumGuaranteeAtExpiry' },
          { name: 'finalAge' }
        ]
      }
    },
    TH: {
      default: {
        parametersValues: []
      }
    },
    EX: {
      default: {
        parametersValues: []
      }
    },
    BL: {
      default: {
        parametersValues: []
      }
    },
    PR: {
      default: {
        parametersValues: []
      }
    },
    DS: {
      default: {
        parametersValues: [
          { name: 'insuredPerson' },
          { name: 'occupationalDisability' },
          { name: 'pensionGuaranteePeriod' },
          { name: 'careOption' },
          { name: 'deathBeforeAnnuity' },
          { name: 'premiumGuaranteeAtExpiry' },
          { name: 'finalAge' }
        ]
      }
    },
    IN: {
      default: {
        parametersValues: []
      }
    },
    CR: {
      default: {
        parametersValues: []
      }
    },
    DB: {
      default: {
        parametersValues: []
      }
    },
    HI: {
      default: {
        parametersValues: []
      }
    },
    SH: {
      default: {
        parametersValues: []
      }
    },
    CH: {
      default: {
        parametersValues: []
      }
    },
    SU: {
      default: {
        parametersValues: []
      }
    },
    MI: {
      default: {
        parametersValues: [
          { name: 'loss' },
          { name: 'operatingErrorsAndClumsiness' },
          { name: 'sabotageAndVandalism' },
          { name: 'replacementAfterDamage' },
          { name: 'frost' },
          { name: 'GAPCover' },
          { name: 'scopeOfApplication' },
          { name: 'grossNegligenceObligations' },
          { name: 'grossNegligenceDamage' },
          { name: 'internalBusinessLosses' },
          { name: 'internalDisturbances' },
          { name: 'shortCircuitOvercurrentOvervoltage' },
          { name: 'compensationForReplacementValue' },
          { name: 'immediateStartOfRepair' },
          { name: 'undergroundAndTunnelWork' },
          { name: 'accidentFromExternalImpact' },
          { name: 'underinsuranceWaiver' },
          { name: 'improvedReplacementValueRegulation' },
          { name: 'rentalIncludingOwnDriver' },
          { name: 'sinkingAndSiltingUp' }
        ]
      }
    },
    LC: {
      default: {
        parametersValues: []
      }
    },
    PL: {
      default: {
        parametersValues: []
      }
    },
    TC: {
      default: {
        parametersValues: []
      }
    },
    AN: {
      default: {
        parametersValues: [
          { name: 'insuredPerson' },
          { name: 'occupationalDisability' },
          { name: 'pensionGuaranteePeriod' },
          { name: 'careOption' },
          { name: 'deathBeforeAnnuity' },
          { name: 'premiumGuaranteeAtExpiry' },
          { name: 'finalAge' }
        ]
      }
    },
    RP: {
      default: {
        parametersValues: [
          { name: 'insuredPerson' },
          { name: 'occupationalDisability' },
          { name: 'pensionGuaranteePeriod' },
          { name: 'careOption' },
          { name: 'deathBeforeAnnuity' },
          { name: 'premiumGuaranteeAtExpiry' },
          { name: 'finalAge' }
        ]
      }
    },
    AO: {
      default: {
        parametersValues: []
      }
    },
    AH: {
      default: {
        parametersValues: []
      }
    },
    LF: {
      default: {
        parametersValues: [
          { name: 'insuredPerson' },
          { name: 'occupationalDisability' },
          { name: 'pensionGuaranteePeriod' },
          { name: 'careOption' },
          { name: 'deathBeforeAnnuity' },
          { name: 'premiumGuaranteeAtExpiry' },
          { name: 'finalAge' }
        ]
      }
    },
    OD: {
      default: {
        parametersValues: [
          { name: 'insuredPerson' },
          { name: 'occupationalDisability' },
          { name: 'pensionGuaranteePeriod' },
          { name: 'careOption' },
          { name: 'deathBeforeAnnuity' },
          { name: 'premiumGuaranteeAtExpiry' },
          { name: 'finalAge' }
        ]
      }
    },
    WD: {
      default: {
        parametersValues: []
      }
    },
    BC: {
      default: {
        parametersValues: [
          { name: 'insuredPerson' },
          { name: 'occupationalDisability' },
          { name: 'pensionGuaranteePeriod' },
          { name: 'careOption' },
          { name: 'deathBeforeAnnuity' },
          { name: 'premiumGuaranteeAtExpiry' },
          { name: 'finalAge' }
        ]
      }
    },
    SV: {
      default: {
        parametersValues: []
      }
    },
    OL: {
      default: {
        parametersValues: []
      }
    },
    LP: {
      default: {
        parametersValues: []
      }
    },
    CP: {
      default: {
        parametersValues: []
      }
    },
    LO: {
      default: {
        parametersValues: []
      }
    },
    EM: {
      default: {
        parametersValues: []
      }
    },
    DR: {
      default: {
        parametersValues: [
          { name: 'insuredPerson' },
          { name: 'occupationalDisability' },
          { name: 'pensionGuaranteePeriod' },
          { name: 'careOption' },
          { name: 'deathBeforeAnnuity' },
          { name: 'premiumGuaranteeAtExpiry' },
          { name: 'finalAge' }
        ]
      }
    },
    FU: {
      default: {
        parametersValues: []
      }
    },
    CM: {
      default: {
        parametersValues: []
      }
    },
    WA: {
      default: {
        parametersValues: []
      }
    },
    BA: {
      default: {
        parametersValues: [
          { name: 'insuredPerson' },
          { name: 'occupationalDisability' },
          { name: 'pensionGuaranteePeriod' },
          { name: 'careOption' },
          { name: 'deathBeforeAnnuity' },
          { name: 'premiumGuaranteeAtExpiry' },
          { name: 'finalAge' }
        ]
      }
    },
    HU: {
      default: {
        parametersValues: []
      }
    },
    DH: {
      default: {
        parametersValues: []
      }
    },
    AR: {
      default: {
        parametersValues: []
      },
      gastronomy: {
        parametersValues: [
          {
            name: 'missingADeadline'
          }
        ]
      },
      craftwork: {
        parametersValues: [
          {
            name: 'falseAdvice'
          }
        ]
      }
    },
    SP: {
      default: {
        parametersValues: []
      }
    },
    LU: {
      default: {
        parametersValues: [
          {
            name: 'insuredPerson'
          },
          {
            name: 'occupationalDisability'
          },
          {
            name: 'pensionGuaranteePeriod'
          },
          {
            name: 'careOption'
          },
          {
            name: 'deathBeforeAnnuity'
          },
          {
            name: 'premiumGuaranteeAtExpiry'
          },
          {
            name: 'finalAge'
          }
        ]
      }
    },
    LL: {
      default: {
        parametersValues: [
          { name: 'insuredPerson' },
          { name: 'occupationalDisability' },
          { name: 'pensionGuaranteePeriod' },
          { name: 'careOption' },
          { name: 'deathBeforeAnnuity' },
          { name: 'premiumGuaranteeAtExpiry' },
          { name: 'finalAge' }
        ]
      }
    },
    RL: {
      default: {
        parametersValues: []
      }
    },
    MU: {
      default: {
        parametersValues: []
      }
    },
    NC: {
      default: {
        parametersValues: []
      }
    },
    DN: {
      default: {
        parametersValues: []
      }
    },
    BG: {
      default: {
        parametersValues: []
      }
    },
    PC: {
      default: {
        parametersValues: [
          { name: 'insuredPerson' },
          { name: 'occupationalDisability' },
          { name: 'pensionGuaranteePeriod' },
          { name: 'careOption' },
          { name: 'deathBeforeAnnuity' },
          { name: 'premiumGuaranteeAtExpiry' },
          { name: 'finalAge' }
        ]
      }
    },
    ST: {
      default: {
        parametersValues: [
          { name: 'insuredPerson' },
          { name: 'occupationalDisability' },
          { name: 'pensionGuaranteePeriod' },
          { name: 'careOption' },
          { name: 'deathBeforeAnnuity' },
          { name: 'premiumGuaranteeAtExpiry' },
          { name: 'finalAge' }
        ]
      }
    },
    RI: {
      default: {
        parametersValues: [
          { name: 'insuredPerson' },
          { name: 'occupationalDisability' },
          { name: 'pensionGuaranteePeriod' },
          { name: 'careOption' },
          { name: 'deathBeforeAnnuity' },
          { name: 'premiumGuaranteeAtExpiry' },
          { name: 'finalAge' }
        ]
      }
    },
    PO: {
      default: {
        parametersValues: []
      }
    },
    ML: {
      default: {
        parametersValues: []
      }
    },
    OT: {
      default: {
        parametersValues: []
      }
    },
    SB: {
      default: {
        parametersValues: []
      }
    },
    DE: {
      default: {
        parametersValues: [
          {
            name: 'insuredPerson'
          },
          {
            name: 'occupationalDisability'
          },
          {
            name: 'pensionGuaranteePeriod'
          },
          {
            name: 'careOption'
          },
          {
            name: 'deathBeforeAnnuity'
          },
          {
            name: 'premiumGuaranteeAtExpiry'
          },
          {
            name: 'finalAge'
          }
        ]
      }
    },
    TE: {
      default: {
        parametersValues: []
      }
    },
    AP: {
      default: {
        parametersValues: []
      }
    },
    AM: {
      default: {
        parametersValues: []
      }
    },
    TA: {
      default: {
        parametersValues: []
      }
    },
    PP: {
      default: {
        parametersValues: []
      }
    }
  };

interface CategoryHighlights {
  [key: string]: {
    [key: string]: string[];
  };
}

export const defaultHighlightedAttributes: CategoryHighlights = {
  LI: {
    default: [
      'coverageAmountPersonalInjury',
      'coverageAmountPropertyDamage',
      'coverageAmountFinancialLosses',
      'performanceUpdateGuarantee'
    ],
    ecommerceAndRetail: [
      'productLiabilityExtended',
      'coverageAmountPersonalInjury',
      'coverageAmountPropertyDamage',
      'coverageAmountFinancialLosses',
      'performanceUpdateGuarantee'
    ],
    craftwork: [
      'activityRelatedDamages',
      'costOfDefectRectification',
      'comissioningOfSubcontractors',
      'rectificationAccompanyingDamages',
      'additionalCoverageInAccordanceWithAKB'
    ],
    construction: [
      'activityRelatedDamages',
      'costOfDefectRectification',
      'comissioningOfSubcontractors',
      'rectificationAccompanyingDamages',
      'additionalCoverageInAccordanceWithAKB'
    ]
  },
  CO: {
    default: [
      'glassBreakage',
      'lossOfEarnings',
      'newValueCoverage',
      'elementary'
    ],
    gastronomy: [
      'glassBreakage',
      'lossOfEarnings',
      'outdoorPropertyStorm',
      'refrigeratedAndFrozenGoods'
    ]
  },
  LE: {
    default: [
      'specialCriminalLegalProtectionInsurance',
      'corporateContractLegalProtectionInsurance',
      'privateCoverage'
    ]
  },
  FL: {
    default: [],
    startUpAndItServices: [
      'liabilityInsurance',
      'personalDamage',
      'damageToTrust',
      'damageToReputation',
      'keyManCover'
    ],
    consultingBusinessType: [
      'liabilityInsurance',
      'personalDamage',
      'damageToTrust',
      'damageToReputation',
      'keyManCover'
    ],
    marketingAndCreative: [
      'liabilityInsurance',
      'printDamage',
      'damageToTrust',
      'damageToReputation'
    ]
  },
  CY: {
    default: [
      'cyberLossOfEarningsOnPremises',
      'cyberLossOfEarningsCloud',
      'cyberBlackmail',
      'cyberPayments'
    ]
  },
  EL: {
    default: [
      'cleanupCosts',
      'dataInsurance',
      'lossOfEarnings',
      'mobileUsedItems',
      'underinsuranceWaiver'
    ]
  }
};

export const isDefaultHighlightedAttributes = (
  categoryId: string,
  businessVertical: BusinessVertical,
  attribute: string
) => {
  const category =
    defaultHighlightedAttributes[
      categoryId as keyof typeof defaultHighlightedAttributes
    ];

  if (!category) return false;

  const vertical = category[businessVertical as keyof typeof category] || [];

  if (vertical.length === 0) return false;

  return vertical.includes(attribute);
};

export const autoRecommendationKeys = [
  'LI',
  'CO',
  'LE',
  'FL',
  'CY',
  'EL'
] as const;

import {
  SaveSelfAssesmentPayload,
  createAssessment,
  deleteAssessment,
  getAssessment,
  getSelfAssessments,
  redoAssessment,
  saveSelfAssessment
} from '@/api';
import { useInvalidateOnSuccessMutation } from '@/utils';
import { useQuery } from 'react-query';

const keys = {
  assesment: 'assesment',
  selfAssesment: 'selfAssesment',
  company: 'company',
  location: 'location'
};

export const useAssesment = (id: string, options: any) =>
  useQuery([keys.assesment, id], () => getAssessment(id), options);

export const useSelfAssesment = (
  id: string,
  options: Record<string, unknown> = {}
) => useQuery([keys.selfAssesment, id], () => getSelfAssessments(id), options);

export const useCreateAssesment = (id: string, categoryId: string) =>
  useInvalidateOnSuccessMutation(
    [keys.assesment, ['location', id]],
    ({ inputData }) =>
      createAssessment({
        categoryId,
        inputData,
        locationId: id
      })
  );

export const useSaveSelfAssesment = (locationId: string, companyId: string) =>
  useInvalidateOnSuccessMutation(
    [
      [keys.selfAssesment, locationId],
      [keys.location, locationId],
      [keys.company, companyId]
    ],
    ({ assesment }: SaveSelfAssesmentPayload) =>
      saveSelfAssessment({ locationId: locationId, assesment })
  );

export const useDeleteAssessment = (id: string) =>
  useInvalidateOnSuccessMutation('location', (assessmentId: string) =>
    deleteAssessment(assessmentId || id)
  );

export const useRedoAssesment = (id: string) =>
  useInvalidateOnSuccessMutation('location', () => redoAssessment(id));

import {
  assoc,
  curry,
  either,
  ifElse,
  keys,
  map,
  pipe,
  reduce,
  when
} from 'ramda';
import { isArray, isObject } from './type';

export const renameObjKeysBy: any = curry((func, obj) =>
  when(
    either(isObject, isArray),
    ifElse(
      isArray,
      map(renameObjKeysBy(func)),
      pipe(
        keys,
        reduce(
          (acc, cur) => assoc(func(cur), renameObjKeysBy(func, obj[cur]), acc),
          {}
        )
      )
    ),
    obj
  )
);

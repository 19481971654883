import { api } from './api';

export const getAttributes = async (
  language = 'de'
): Promise<{
  getAttributeLabel: (code: string) => string;
  getAttributeDescription: (code: string) => string | undefined;
}> => {
  const attributes: Record<string, { name: string; description?: string }> =
    await api.get(`/public/attributes?language=${language}`);

  return {
    /**
     * Returns the code as a fallback
     */
    getAttributeLabel(code: string): string {
      const label = attributes[code]?.name;

      if (!label) {
        console.warn(`No attribute translation found for code: ${code}`);
      }

      return label ?? code;
    },

    getAttributeDescription(code: string): string | undefined {
      return attributes[code]?.description;
    }
  };
};

import React, { useEffect, useState } from 'react';

import {
  BusinessTypeSelector,
  Datepicker,
  Loader,
  LocationAutocomplete
} from '@/components';
import { useCompany, useSaveSelfAssesment, useSelfAssesment } from '@/services';
import {
  locationAutocompleteRule,
  nonNegativeRule,
  safeTimestamp,
  timestampToDateString,
  toInt
} from '@/utils';
import { Button, Checkbox, Form, Input, Modal, Radio, Space } from 'antd';
import { useParams } from 'react-router-dom';
import './styles.scss';

import { makeBusinessTypePayload } from '@/components/BusinessTypeSelector/helpers';
import { Company } from '@/types/company';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { evolve, omit } from 'ramda';

interface AssessmentComponentProps {
  companyData: Company;
  edit: boolean;
}

const modifier = evolve({
  employeesCount: {
    ownersCount: toInt,
    fullTimeEmployeesCount: toInt,
    partTimeEmployeesCount: toInt,
    miniJobbersCount: toInt
  },
  lastYearFinances: {
    approxTurnover: parseFloat,
    approxCostOfGoods: parseFloat,
    approxPersonelExpenses: parseFloat
  },
  locationRent: {
    rentedArea: parseFloat,
    totalEquipmentsCost: parseFloat,
    annualBruttoRent: parseFloat
  },
  companyFoundedDate: {
    foundedDate: safeTimestamp
  },
  companyDamage: {
    lastDamageDateLi: timestampToDateString
  },
  businessType: makeBusinessTypePayload
});

const AssesmentComponent = ({
  companyData,
  edit
}: AssessmentComponentProps) => {
  const form = Form.useFormInstance();

  const handleSameAddress = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      form.setFieldValue(
        ['locationDetails', 'address'],
        form.getFieldValue(['companyDetails', 'address'])
      );
    } else {
      form.setFieldValue(['locationDetails', 'address'], {
        country: 'Germany'
      });
    }
  };

  return (
    <>
      <BusinessTypeSelector />
      <Form.Item
        label="Company Name"
        name={['companyDetails', 'name']}
        rules={[{ required: true, message: 'Required!' }]}
      >
        <Input disabled={companyData?.poaSigned || !edit} />
      </Form.Item>
      <Form.Item
        label="Address"
        name={['companyDetails', 'address']}
        rules={[
          { required: true, message: 'Required!' },
          locationAutocompleteRule
        ]}
      >
        <LocationAutocomplete
          disabled={companyData?.poaSigned || !edit}
          name={['companyDetails', 'address']}
        />
      </Form.Item>
      <Form.Item
        label="Company founded date"
        name={['companyFoundedDate', 'foundedDate']}
        rules={[{ required: true, message: 'Required!' }]}
      >
        <Datepicker disabled={!edit} />
      </Form.Item>
      <Form.Item
        label="Location Name"
        name={['locationDetails', 'name']}
        rules={[{ required: true, message: 'Required!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Location Address"
        name={['locationDetails', 'address']}
        rules={[
          { required: true, message: 'Required!' },
          locationAutocompleteRule
        ]}
      >
        <LocationAutocomplete
          name={['locationDetails', 'address']}
          handleSame={handleSameAddress}
        />
      </Form.Item>
      <Form.Item
        name={['locationOpenSeason', 'openSeason']}
        label="Is your company open the whole year?"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Space direction="vertical">
            <Radio value="allYear">🌲 Yes, we’re open all year</Radio>
            <Radio value="notAllYear">
              🍁 No, we’re open less than 8 months per year
            </Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: 'Required!' }, nonNegativeRule]}
        name={['employeesCount', 'ownersCount']}
        label="Owners count"
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: 'Required!' }, nonNegativeRule]}
        name={['employeesCount', 'fullTimeEmployeesCount']}
        label="Full-time employees count"
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: 'Required!' }, nonNegativeRule]}
        name={['employeesCount', 'partTimeEmployeesCount']}
        label="Part-time employees count"
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: 'Required!' }, nonNegativeRule]}
        name={['employeesCount', 'miniJobbersCount']}
        label="Minijobber count"
      >
        <Input type="number" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: 'Required!' }, nonNegativeRule]}
        name={['lastYearFinances', 'approxTurnover']}
        label="💶 Your approximate turnover"
      >
        <Input type="number" addonBefore="€" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: 'Required!' }, nonNegativeRule]}
        name={['lastYearFinances', 'approxCostOfGoods']}
        label="📦 Approximate cost of goods"
      >
        <Input type="number" addonBefore="€" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: 'Required!' }, nonNegativeRule]}
        name={['lastYearFinances', 'approxPersonelExpenses']}
        label="🙋‍♀️ Approximate personnel costs"
      >
        <Input type="number" addonBefore="€" />
      </Form.Item>
      <Form.Item
        label="Location activities"
        name={['locationActivities', 'essentialCategoryId']}
      >
        <Checkbox.Group>
          <div className="scopesCheckboxes">
            <Checkbox value="TR">📦 We deliver food or offer catering</Checkbox>
            <Checkbox value="CA">🚚 We own and use cars</Checkbox>
            <Checkbox value="BU">🏠 We are the owner of the premises</Checkbox>
            <Checkbox value="EL">
              🔭 Electronic equipment worth more than 20k€
            </Checkbox>
          </div>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: 'Required!' }, nonNegativeRule]}
        name={['locationRent', 'rentedArea']}
        label="Total rented area (m2) 📏"
      >
        <Input type="number" addonBefore="sqm" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: 'Required!' }, nonNegativeRule]}
        name={['locationRent', 'annualBruttoRent']}
        label="Your total annual gross rent 💶"
      >
        <Input type="number" addonBefore="€" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: 'Required!' }, nonNegativeRule]}
        name={['locationRent', 'totalEquipmentsCost']}
        label="Total equipments cost 📦"
      >
        <Input type="number" addonBefore="€" />
      </Form.Item>
      <Form.Item
        name={['companyDamage', 'lastDamageDateLi']}
        label="Last liability damage date"
      >
        <Datepicker disabled={!edit} />
      </Form.Item>
      <Form.Item
        rules={[nonNegativeRule]}
        name={['companyDamage', 'lastDamageValueLi']}
        label="Last liability damage value"
      >
        <Input type="number" addonBefore="€" />
      </Form.Item>
      <Form.Item
        rules={[nonNegativeRule]}
        name={['constructionDetails', 'constructionArea']}
        label="Building construction area"
      >
        <Input type="number" addonBefore="sqm" />
      </Form.Item>
      <Form.Item
        rules={[nonNegativeRule]}
        name={['constructionDetails', 'constructionDate']}
        label="Building construction date"
      >
        <Datepicker disabled={!edit} />
      </Form.Item>
      <Form.Item
        rules={[nonNegativeRule]}
        name={['constructionDetails', 'constructionValue']}
        label="Building construction value"
      >
        <Input type="number" addonBefore="€" />
      </Form.Item>
    </>
  );
};

export const SelfAssesmentModal = () => {
  const { locationId, companyId } = useParams<{
    locationId: string;
    companyId: string;
  }>();
  const { data: companyData } = useCompany(companyId);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [edit, setEdit] = useState(false);
  const saveSelfAssesment = useSaveSelfAssesment(locationId, companyId);

  const handleCancel = (hide = true) => {
    form.resetFields();
    setEdit(false);
    if (hide) setVisible(false);
  };
  const { data: selfAssesment, isLoading } = useSelfAssesment(locationId);

  // TODO: define type
  const handleSubmit = (inputData: any) => {
    // Taking out companyDetails from the form data
    // because the backend expects them to not be sent if a PoA is signed
    // else resulting in a 400 error
    const formData = companyData?.poaSigned
      ? omit(['companyDetails'], inputData)
      : inputData;
    saveSelfAssesment
      .mutateAsync({
        assesment: modifier(formData)
      })
      .then(() => handleCancel());
  };

  useEffect(() => {
    // without reset the form is buggy around the locationTypeSpecification
    form.resetFields();
    form?.setFieldsValue(selfAssesment?.values || {});
  }, [form, selfAssesment?.values, visible]);

  if (isLoading || !companyData) return <Loader />;

  const FooterComponent = (
    <div>
      <Button onClick={() => handleCancel(!edit)}>
        {edit ? 'Cancel' : 'Close'}
      </Button>
      {edit ? (
        <Button type="primary" onClick={() => form.submit()}>
          Save
        </Button>
      ) : (
        <Button type="primary" onClick={() => setEdit(true)}>
          Edit
        </Button>
      )}
    </div>
  );

  return (
    <>
      <Button onClick={() => setVisible(true)} type="link">
        View
      </Button>
      <Modal
        centered
        footer={FooterComponent}
        title="Self Assesment"
        closable={false}
        cancelText="Close"
        okText="Submit"
        className="add-company-modal"
        open={visible}
        forceRender
      >
        <Form
          // setting initial values makes this form buggy - see useEffect
          disabled={!edit}
          className="assesmentForm"
          layout="vertical"
          form={form}
          style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}
          onFinish={handleSubmit}
          onValuesChange={(changed) => {
            const locationSubtypes =
              changed?.businessType?.locationSubtypes ?? [];

            if (
              locationSubtypes.includes('other') &&
              locationSubtypes.length > 1
            ) {
              form.setFieldValue(
                ['businessType', 'locationSubtypes'],
                locationSubtypes.filter((v: string) => v !== 'other')
              );
            }
          }}
        >
          <AssesmentComponent companyData={companyData} edit={edit} />
        </Form>
      </Modal>
    </>
  );
};

import React, { useState } from 'react';

import { CategoryLabelMap } from '@/enums';
import { useCreateRecommendation } from '@/services';
import { useGoToPath } from '@/utils';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select } from 'antd';
import { concat, keys, pipe } from 'ramda';
import { useParams } from 'react-router-dom';
import './styles.scss';
import { BusinessVertical } from '@/enums/businessVertical';

interface AddRecommendationModalProps {
  disabled: boolean;
  locationBusinessVertical: BusinessVertical;
}

export const AddRecommendation = ({
  disabled,
  locationBusinessVertical
}: AddRecommendationModalProps) => {
  const { locationId } = useParams<{ locationId: string }>();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>();

  const businessVertical: BusinessVertical = locationBusinessVertical;

  const addRecommendationMutation = useCreateRecommendation({
    locationId
  });
  const goToRecommendation = useGoToPath(
    pipe(String, concat('/recommendation/'))
  );

  const onSubmit = () => {
    return form.validateFields().then((values) => {
      addRecommendationMutation
        .mutateAsync({ ...values, businessVertical })
        .then(({ data }) => {
          setVisible(false);
          goToRecommendation(data.recommendationId);
        });
    });
  };

  return (
    <>
      <Button disabled={disabled} type="link" onClick={() => setVisible(true)}>
        <PlusCircleOutlined />
        Add recommendation
      </Button>
      <Modal
        centered
        onOk={onSubmit}
        onCancel={() => setVisible(false)}
        okText="Next"
        title="Add recommendation"
        closable={false}
        className="add-company-modal"
        open={visible}
      >
        <Form
          layout="vertical"
          form={form}
          style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}
        >
          <Form.Item
            label="Product"
            name="categoryId"
            rules={[{ required: true, message: 'Required!' }]}
          >
            <Select
              showSearch
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  ?.toUpperCase()
                  .startsWith(inputValue?.toUpperCase())
              }
              options={keys(CategoryLabelMap)
                .sort((a, b) =>
                  CategoryLabelMap[a].localeCompare(CategoryLabelMap[b])
                )
                .map((item) => ({
                  value: item,
                  label: CategoryLabelMap[item]
                }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

import {
  InsuranceProduct,
  InsuranceProductWithCarrier
} from '@/types/insurance';
import { api } from './api';

export const listInsuranceProducts = () =>
  api.get<InsuranceProductWithCarrier[]>('/insurance_product');

export const insuranceProductsDetails = (id: string) =>
  api.get(`/insurance_product/${id}`);

export const deleteInsuranceProduct = (id: string) =>
  api.delete(`/insurance_product/${id}`);

export const createInsuranceProduct = ({
  categoryId,
  businessVertical,
  carrierId,
  name,
  description,
  deductible,
  commission,
  parametersValues = {},
  yesNoValues = {},
  highlightedAttributes = {},
  options = {},
  contractCoverageRating,
  agreementNumber
}: InsuranceProduct) =>
  api.post('/insurance_product', {
    categoryId,
    businessVertical,
    carrierId,
    name,
    description,
    deductible,
    commission,
    parametersValues,
    highlightedAttributes,
    yesNoValues,
    options,
    contractCoverageRating,
    agreementNumber
  });

export const updateInsuranceProduct = ({
  insuranceProductId,
  categoryId,
  businessVertical,
  carrierId,
  name,
  contractCoverageRating,
  description,
  deductible,
  commission,
  agreementNumber,
  parametersValues = {},
  yesNoValues = {},
  options = {},
  highlightedAttributes = {},
  isArchived,
  multiRiskAllowed
}: Partial<InsuranceProduct>) =>
  api.put(`/insurance_product/${insuranceProductId}`, {
    categoryId,
    carrierId,
    businessVertical,
    name,
    contractCoverageRating,
    description,
    deductible,
    commission,
    parametersValues,
    yesNoValues,
    agreementNumber,
    options,
    highlightedAttributes,
    isArchived,
    multiRiskAllowed
  });

export const archiveInsuranceProduct = ({
  insuranceProductId,
  isArchived
}: Partial<InsuranceProduct>) =>
  api.put(`/insurance_product/${insuranceProductId}`, { isArchived });

export const getActiveInsuranceProducts = () =>
  api.get<InsuranceProductWithCarrier[]>('/insurance_product/active');

export const getArchivedInsuranceProducts = () =>
  api.get<InsuranceProductWithCarrier[]>('/insurance_product/archived');

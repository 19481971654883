import React from 'react';

import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Radio } from 'antd';
import './styles.scss';

interface YesNoProps {
  onChange?: (value?: number) => void;
  value?: number;
  onChangeAddon?: (value?: number) => void;
  disabled?: boolean;
}

export const YesNo = ({
  onChange,
  value,
  onChangeAddon,
  ...props
}: YesNoProps) => (
  <Radio.Group
    className="yesNoRadio"
    options={[
      {
        label: (
          <div
            onClick={() => {
              if (value === 1) {
                onChange && onChange();
                onChangeAddon?.();
              } else {
                onChange && onChange(1);
                onChangeAddon?.(1);
              }
            }}
          >
            <CheckCircleFilled /> Yes
          </div>
        ),
        value: 1
      },
      {
        label: (
          <div
            onClick={() => {
              if (value === 0) {
                onChange && onChange();
                onChangeAddon?.();
              } else {
                onChange && onChange(0);
                onChangeAddon?.(0);
              }
            }}
          >
            <CloseCircleFilled /> No
          </div>
        ),
        value: 0
      }
    ]}
    value={value}
    optionType="button"
    buttonStyle="solid"
    {...props}
  />
);

import {
  archiveInsuranceProduct,
  createInsuranceProduct,
  deleteInsuranceProduct,
  getActiveInsuranceProducts,
  getArchivedInsuranceProducts,
  insuranceProductsDetails,
  listInsuranceProducts,
  updateInsuranceProduct
} from '@/api';
import { InsuranceProductWithCarrier } from '@/types/insurance';
import { useInvalidateOnSuccessMutation } from '@/utils';
import { useQuery, UseQueryOptions } from 'react-query';

const keys = {
  insuranceProducts: 'insuranceProducts',
  insuranceProduct: 'insuranceProduct'
};

export const useInsuranceProducts = <T = InsuranceProductWithCarrier[]>(
  options?: UseQueryOptions<InsuranceProductWithCarrier[], unknown, T, string>
) => useQuery(keys.insuranceProducts, listInsuranceProducts, options);

export const useInsuranceProduct = (
  id: string,
  options?: UseQueryOptions<
    InsuranceProductWithCarrier,
    unknown,
    InsuranceProductWithCarrier,
    string[]
  >
) =>
  useQuery(
    [keys.insuranceProduct, id],
    () => insuranceProductsDetails(id),
    options
  );

export const useDeleteInsuranceProduct = () =>
  useInvalidateOnSuccessMutation(keys.insuranceProducts, (id) =>
    deleteInsuranceProduct(id)
  );

export const useCreateInsuranceProduct = () =>
  useInvalidateOnSuccessMutation<
    InsuranceProductWithCarrier,
    unknown,
    Record<string, unknown>,
    unknown
  >(keys.insuranceProducts, createInsuranceProduct);

export const useUpdateInsuranceProduct = (insuranceProductId: string) =>
  useInvalidateOnSuccessMutation<
    InsuranceProductWithCarrier,
    unknown,
    Record<string, unknown>,
    unknown
  >(
    [keys.insuranceProducts, [keys.insuranceProduct, insuranceProductId]],
    ({
      categoryId,
      businessVertical,
      carrierId,
      name,
      contractCoverageRating,
      description,
      deductible,
      commission,
      parametersValues,
      yesNoValues,
      agreementNumber,
      options,
      highlightedAttributes,
      multiRiskAllowed
    }) =>
      updateInsuranceProduct({
        insuranceProductId,
        categoryId,
        businessVertical,
        carrierId,
        name,
        contractCoverageRating,
        description,
        deductible,
        commission,
        parametersValues,
        yesNoValues,
        options,
        agreementNumber,
        highlightedAttributes,
        multiRiskAllowed
      })
  );

export const useArchiveInsuranceProduct = () =>
  useInvalidateOnSuccessMutation(
    keys.insuranceProducts,
    ({ insuranceProductId, isArchived }) =>
      archiveInsuranceProduct({ insuranceProductId, isArchived })
  );

export const useGetActiveInsuranceProducts = (
  options?: UseQueryOptions<
    InsuranceProductWithCarrier[],
    unknown,
    InsuranceProductWithCarrier[],
    string[]
  >
) =>
  useQuery(
    [keys.insuranceProducts, 'active'],
    getActiveInsuranceProducts,
    options
  );

export const useGetArchivedInsuranceProducts = (
  options?: UseQueryOptions<
    InsuranceProductWithCarrier[],
    unknown,
    InsuranceProductWithCarrier[],
    string[]
  >
) =>
  useQuery(
    [keys.insuranceProducts, 'archived'],
    getArchivedInsuranceProducts,
    options
  );

import React, { useState } from 'react';

import { Datepicker, Loader, NextPossible, UpdatableField } from '@/components';
import {
  useAssesment,
  useCreateAssesment,
  useDeleteAssessment,
  useRedoAssesment
} from '@/services/assesment';
import {
  Modal,
  Form,
  Checkbox,
  message,
  Select,
  Input,
  Radio,
  Button,
  Popconfirm
} from 'antd';
import { toInt } from '@/utils';
import './styles.scss';
import { evolve } from 'ramda';
import { useParams } from 'react-router-dom';
import { useUpdateLocationType } from '@/services';
import { Upload } from '@/components/Upload';
import { InputData } from '@/types/self-assessment';

const modifier = evolve({
  annualRent: parseFloat,
  annualWages: parseFloat,
  annualSales: parseFloat,
  annualGrossRent: parseFloat,
  vehiclesNr: toInt,
  ownersCount: toInt,
  fullTimeNr: toInt,
  familyMembersCount: toInt,
  miniJobbersNr: toInt,
  partTimeNr: toInt,
  traineesCount: toInt,
  damageValue: parseFloat,
  contentValue: parseFloat,
  firstDamageValueLi: parseFloat,
  firstDamageYearLi: toInt,
  secondDamageValueLi: parseFloat,
  secondDamageYearLi: toInt,
  overallDamageValueLi: parseFloat,
  firstDamageValueCo: parseFloat,
  firstDamageYearCo: toInt,
  secondDamageValueCo: parseFloat,
  secondDamageYearCo: toInt,
  overallDamageValueCo: parseFloat,
  surface: parseFloat
});

const LegalForm = ({ edit }: LegalFormProps) => {
  const { getFieldValue } = Form.useFormInstance();

  return (
    <>
      <Form.Item name="companyFounded" label="When was your company founded?">
        <Datepicker value="" disabled={!edit} />
      </Form.Item>
      <Form.Item label="Gewerblicher Deckungsumfang?" name="scopes">
        <Checkbox.Group>
          <div className="scopesCheckboxes">
            <Checkbox value="general" disabled>
              Allgemeiner Rechtsschutz
            </Checkbox>
            <Checkbox value="labour">Arbeits-Rechtsschutz</Checkbox>
            <Checkbox value="estate">Immobilien Rechtsschutz</Checkbox>
            <UpdatableField>
              {() =>
                getFieldValue('scopes').includes('estate') && (
                  <div className="assesment-modal-scopesCheckboxes-container">
                    <Form.Item
                      label="Eigentümer oder Mieter des Objekts?"
                      name="scopeRole"
                    >
                      <Select
                        options={[
                          { value: 'tenant', label: 'Eigentümer' },
                          { value: 'owner', label: 'Mieter' }
                        ]}
                      />
                    </Form.Item>
                    {getFieldValue('scopeRole') === 'tenant' && (
                      <Form.Item
                        label="Jahresbruttomiete/-pacht"
                        name="annualGrossRent"
                      >
                        <Input type="number" addonBefore="€" />
                      </Form.Item>
                    )}
                  </div>
                )
              }
            </UpdatableField>
            <Checkbox value="traffic">Verkehrs Rechtsschutz</Checkbox>
            <UpdatableField>
              {() =>
                getFieldValue('scopes').includes('traffic') && (
                  <div className="assesment-modal-scopesCheckboxes-container">
                    <Form.Item
                      label="Menge an Fahrzeugen bis zu 4t Last?"
                      name="vehiclesNr"
                    >
                      <Input type="number" />
                    </Form.Item>
                  </div>
                )
              }
            </UpdatableField>
          </div>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name="criminalProtectionInclusion"
        label="Einschluss Spezial-Straf-Rechtsschutz?"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="privateRisks" label="Mitversicherung privater Risiken?">
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="ownersCount" label="Anzahl Inhaber und Geschäftsführer">
        <Input type="number" />
      </Form.Item>
      <Form.Item
        name="familyMembersCount"
        label="Anzahl der mitarbeitenden Familienangehörigen"
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item name="fullTimeNr" label="Anzahl der Vollzeitbeschäftigten">
        <Input type="number" />
      </Form.Item>
      <Form.Item name="partTimeNr" label="Anzahl der Teilzeitbeschäftigten">
        <Input type="number" />
      </Form.Item>
      <Form.Item
        name="miniJobbersNr"
        label="Wie viele der Teilzeitbeschäftigten sind 450€-Minijobber?"
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item name="traineesCount" label="Anzahl Auszubildende">
        <Input type="number" />
      </Form.Item>
      <Form.Item
        name="debtCollectionProtection"
        label="Mitversicherung Inkasso-Rechtsschutz"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="insuranceContractProtection"
        label="Mitversicherung Versicherungs-Vertrags-Rechtsschutz"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="auxiliaryBusinessProteciton"
        label="Mitversicherung Hilfsgeschäfte-Vertrags-Rechtsschutz"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="companyContractProtection"
        label="Mitversicherung Firmen-Vertrags-Rechtsschutz"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="comparableScopeAvailable"
        label="Vorversicherung (vergleichbarer Umfang) vorhanden"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="comparableScopeRejection"
        label="Ablehnung eines vergleichbaren Antrages in der Vergangenheit"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="damageCount"
        label="Schäden (auch unversicherte) in den letzten 7 Jahren"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value="0">Keine</Radio>
          <Radio value="1">1</Radio>
          <Radio value="2">2</Radio>
          <Radio value=">2">mehr als 2</Radio>
          <UpdatableField>
            {() =>
              getFieldValue('damageCount') !== '0' ? (
                <div className="assesment-modal-scopesCheckboxes-container">
                  <Form.Item
                    name="damageValue"
                    label="Wie hoch war die gemeldete Schadenssumme?"
                  >
                    <Input type="number" addonBefore="€" />
                  </Form.Item>
                </div>
              ) : null
            }
          </UpdatableField>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="criminalInvestigation"
        label="Laufendes oder zu erwartendes strafrechtliches Ermittlungsverfahren (Antragsteller oder mitversicherte Person)"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="annualSales" label="Jahresumsatz (ohne MwSt.) (€)">
        <Input type="number" addonBefore="€" />
      </Form.Item>
      <Form.Item
        name="annualWages"
        label="Jährliche Lohn- und Gehaltskosten (€)"
      >
        <Input type="number" addonBefore="€" />
      </Form.Item>
      <span className="assesmentForm-dateOrNext-label">
        Gewünschter Versicherungsbeginn?
      </span>
      <Form.Item name="desiredStartDateLE">
        <NextPossible disabled={!edit} />
      </Form.Item>
    </>
  );
};
const CombinedForm = ({ edit }: CombinedFormProps) => {
  const { getFieldValue } = Form.useFormInstance();

  return (
    <>
      <Form.Item
        name="locationType"
        label="Welche Art von Unternehmen hast Du?"
      >
        <Select
          options={[
            { value: 'restaurant', label: 'Restaurant' },
            { value: 'caffee', label: 'Café/Eisdiele' },
            { value: 'snack', label: 'Snackbar/Lieferservice' },
            { value: 'canteen', label: 'Kantine' },
            { value: 'other', label: 'Andere' }
          ]}
        />
      </Form.Item>
      <UpdatableField>
        {() =>
          getFieldValue('locationType') === 'other' ? (
            <Form.Item name="locationOther">
              <Input placeholder="Other location" />
            </Form.Item>
          ) : null
        }
      </UpdatableField>
      <Form.Item name="companyFounded" label="When was your company founded?">
        <Datepicker value="" disabled={!edit} />
      </Form.Item>
      <Form.Item name="fullTimeNr" label="Anzahl der Vollzeitbeschäftigten">
        <Input type="number" />
      </Form.Item>
      <Form.Item name="miniJobbersNr" label="Anzahl der Teilzeitbeschäftigten">
        <Input type="number" />
      </Form.Item>
      <Form.Item
        name="partTimeNr"
        label="Wie viele der Teilzeitbeschäftigten sind 450€-Minijobber?"
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item name="annualSales" label="Jahresumsatz (ohne MwSt.) (€)">
        <Input type="number" addonBefore="€" />
      </Form.Item>
      <Form.Item
        name="annualWages"
        label="Jährliche Lohn- und Gehaltskosten (€)"
      >
        <Input type="number" addonBefore="€" />
      </Form.Item>
      <Form.Item name="surface" label="Größe in Quadratmetern (qm)">
        <Input type="number" />
      </Form.Item>
      <Form.Item
        name="contentValue"
        label="Wert des Inhalts (Neuwert der Waren, Vorräte, Einrichtungsgegenstände) (€)"
      >
        <Input type="number" addonBefore="€" />
      </Form.Item>
      <Form.Item name="annualRent" label="Wie hoch ist Deine Jahresmiete? (€">
        <Input type="number" addonBefore="€" />
      </Form.Item>
      <Form.Item
        name="claimsCountLi"
        label="Wie viele Inhaltsversicherungs-Schadensfälle hattest Du in den letzten 5 Jahren?"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value="0">Keine</Radio>
          <Radio value="1">1</Radio>
          <Radio value="2">2</Radio>
          <Radio value=">2">mehr als 2</Radio>
          <UpdatableField>
            {() => {
              const claimsCountLi = getFieldValue('claimsCountLi');

              return (
                <div className="assesment-modal-scopesCheckboxes-container">
                  {['1', '2'].includes(claimsCountLi) && (
                    <>
                      <Form.Item
                        name="firstDamageYearLi"
                        label="Jahr des 1. Schadenseintritts"
                      >
                        <Input type="number" />
                      </Form.Item>
                      <Form.Item
                        name="firstDamageValueLi"
                        label="Wie hoch war die gemeldete Schadenssumme?"
                      >
                        <Input type="number" addonBefore="€" />
                      </Form.Item>
                    </>
                  )}
                  {claimsCountLi === '2' && (
                    <>
                      <Form.Item
                        name="secondDamageYearLi"
                        label="Jahr des 2. Schadenseintritts"
                      >
                        <Input type="number" />
                      </Form.Item>
                      <Form.Item
                        name="secondDamageValueLi"
                        label="Wie hoch war die gemeldete Schadenssumme?"
                      >
                        <Input type="number" addonBefore="€" />
                      </Form.Item>
                    </>
                  )}
                  {claimsCountLi === '>2' && (
                    <Form.Item
                      name="overallDamageValueLi"
                      label="Wie hoch war die gemeldete Schadenssumme?"
                    >
                      <Input type="number" addonBefore="€" />
                    </Form.Item>
                  )}
                </div>
              );
            }}
          </UpdatableField>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="claimsCountCo"
        label="Wie viele Inhaltsversicherungs-Schadensfälle hattest Du in den letzten 5 Jahren?"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value="0">Keine</Radio>
          <Radio value="1">1</Radio>
          <Radio value="2">2</Radio>
          <Radio value=">2">mehr als 2</Radio>
          <UpdatableField>
            {() => {
              const claimsCountCo = getFieldValue('claimsCountCo');

              return (
                <div className="assesment-modal-scopesCheckboxes-container">
                  {['1', '2'].includes(claimsCountCo) && (
                    <>
                      <Form.Item
                        name="firstDamageYearCo"
                        label="Jahr des 1. Schadenseintritts"
                      >
                        <Input type="number" />
                      </Form.Item>
                      <Form.Item
                        name="firstDamageValueCo"
                        label="Wie hoch war die gemeldete Schadenssumme?"
                      >
                        <Input type="number" addonBefore="€" />
                      </Form.Item>
                    </>
                  )}
                  {claimsCountCo === '2' && (
                    <>
                      <Form.Item
                        name="secondDamageYearCo"
                        label="Jahr des 2. Schadenseintritts"
                      >
                        <Input type="number" />
                      </Form.Item>
                      <Form.Item
                        name="secondDamageValueCo"
                        label="Wie hoch war die gemeldete Schadenssumme?"
                      >
                        <Input type="number" addonBefore="€" />
                      </Form.Item>
                    </>
                  )}
                  {claimsCountCo === '>2' && (
                    <Form.Item
                      name="overallDamageValueCo"
                      label="Wie hoch war die gemeldete Schadenssumme?"
                    >
                      <Input type="number" addonBefore="€" />
                    </Form.Item>
                  )}
                </div>
              );
            }}
          </UpdatableField>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="doorsSecuredWay"
        label="Bitte gib an wie Deine Türen abgesichert sind:"
      >
        <Select
          options={[
            { value: 'doors', label: 'Ganzglas-Schiebetüren' },
            { value: 'flush', label: 'Zylinderschloss' },
            { value: 'barried', label: 'Vergitterte Tür' },
            { value: 'shutter', label: 'Rollladen' }
          ]}
        />
      </Form.Item>
      <Form.Item
        name="exteriorWallsSecured"
        label="Einschluss Spezial-Straf-Rechtsschutz?"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value="yes">Ja</Radio>
          <Radio value="no">Nein</Radio>
          <Radio value="idk">Weiß nicht</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Fragen zur Diebstahlsicherheit"
        name="doorsSecurityStandard"
      >
        <Checkbox.Group>
          <div className="scopesCheckboxes">
            <Checkbox value="allLocable">
              Alle Zugänge sind verschließbar
            </Checkbox>
            <Checkbox value="rearHooks">
              Alle haben innenliegende Türbänder, Scharniere oder sind durch
              Hinterhaken gesichert
            </Checkbox>
            <Checkbox value="unscrewed">
              Alle haben eine Verkleidung oder Blende über dem Türschloss,
              Türschilder sind von außen nicht abschraubbar
            </Checkbox>
            <Checkbox value="closeFlush">
              Alle Schließzylinder sind gegen Herausziehen gesichert und
              schließen außen bündig ab
            </Checkbox>
            <Checkbox value="lockedTwice">
              Alle Zugangstüren können zweimal abgeschlossen werden
            </Checkbox>
          </div>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name="accessDoorMaterial"
        label="Sind in Deinem Betrieb Zugangstüren oder Türrahmen aus Holz, Kunststoff oder Glas vorhanden? Gibt es mehrflügelige Türen oder elektrische Türen?"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
          <UpdatableField>
            {() =>
              getFieldValue('accessDoorMaterial') ? (
                <Form.Item
                  label="Arten der vorhandenen Außentüren"
                  name="exteriorDoorsType"
                >
                  <Checkbox.Group>
                    <div className="scopesCheckboxes">
                      <Checkbox value="glass">Ganzglas-Türen</Checkbox>
                      {getFieldValue('exteriorDoorsType')?.includes(
                        'glass'
                      ) && (
                        <Form.Item
                          label="Sicherungen der Ganzglas-Türen"
                          name="allGlassDoorFuses"
                        >
                          <Checkbox.Group>
                            <div className="scopesCheckboxes">
                              <Checkbox value="none">
                                Keine der genannten Sicherungen
                              </Checkbox>

                              <Checkbox value="thick">
                                Kunststoffzweitscheiben, mind. 5 mm stark, oder
                                durchbruchhemmdende Verglasung
                              </Checkbox>
                              <Checkbox value="anti-lift">
                                Aufhebelsicherungen oder Sicherheitsbeschläge
                                für bewegliche Glaseinsätze
                              </Checkbox>
                              <Checkbox value="solid">
                                Massive, von außen nicht abschraubbare Gitter
                              </Checkbox>
                              <Checkbox value="wooden">
                                Holzinnenblenden mit Feststellvorrichtung
                              </Checkbox>
                              <Checkbox value="metal-roller">
                                Metall-/Holzrolladen oder Roll-/ Scherengitter,
                                jeweils mit Sperrvorrichtung bzw. bündigem
                                Schloss
                              </Checkbox>
                            </div>
                          </Checkbox.Group>
                        </Form.Item>
                      )}
                      <Checkbox value="other">
                        Türen aus sonstigen Materialien
                      </Checkbox>
                      <Checkbox value="wood/plastic">
                        Türen/Türrahmen aus Holz oder Kunststoff
                      </Checkbox>
                      {getFieldValue('exteriorDoorsType')?.includes(
                        'wood/plastic'
                      ) && (
                        <Form.Item
                          label="Türen/Türrahmen aus Holz oder Kunststoff"
                          name="woodPlasticDoors"
                        >
                          <Checkbox.Group>
                            <div className="scopesCheckboxes">
                              <Checkbox value="none">
                                Keine der genannten Sicherungen
                              </Checkbox>

                              <Checkbox value="thick">
                                Türen sind mindestens 4cm dick
                              </Checkbox>
                              <Checkbox value="grille">
                                Türen sind durch ein Gitter geschützt
                              </Checkbox>
                              <Checkbox value="cross-bolt">
                                Türen sind mit einem außen bündigen
                                Querriegelschloss geschützt
                              </Checkbox>
                            </div>
                          </Checkbox.Group>
                        </Form.Item>
                      )}
                      <Checkbox value="multi-leaf">
                        Mehrflügelige Türen
                      </Checkbox>
                      {getFieldValue('exteriorDoorsType')?.includes(
                        'multi-leaf'
                      ) && (
                        <Form.Item
                          label="Mehrflügelige Tür mit Treibriegel oder Stangenschloss"
                          name="multiPanelDoor"
                        >
                          <Radio.Group optionType="button" buttonStyle="solid">
                            <Radio value={1}>Ja</Radio>
                            <Radio value={0}>Nein</Radio>
                          </Radio.Group>
                        </Form.Item>
                      )}
                      <Checkbox value="electric">
                        Elektrisch zu betätigende Türen
                      </Checkbox>
                      {getFieldValue('exteriorDoorsType')?.includes(
                        'electric'
                      ) && (
                        <Form.Item
                          label="Elektrisch zu betätigende Türen"
                          name="electricDoors"
                        >
                          <Radio.Group optionType="button" buttonStyle="solid">
                            <Radio value={1}>Ja</Radio>
                            <Radio value={0}>Nein</Radio>
                          </Radio.Group>
                        </Form.Item>
                      )}
                    </div>
                  </Checkbox.Group>
                </Form.Item>
              ) : null
            }
          </UpdatableField>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="cellarWindow"
        label="Gibt es Kellerfenster, Lichtschächte, Lichtkuppeln oder Oberlichter?"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
          <UpdatableField>
            {() =>
              getFieldValue('cellarWindow') ? (
                <Form.Item
                  label="Sicherungen der Kellerfenster, Lichtschächte, Lichtkuppeln oder Oberlichter"
                  name="windowsSecurity"
                >
                  <Checkbox.Group>
                    <div className="scopesCheckboxes">
                      <Checkbox value="none">Keine der genannten</Checkbox>
                      <Checkbox value="lockable">
                        Abschließbare Stahlgitterfenster
                      </Checkbox>
                      <Checkbox value="secured">
                        Sicherung gegen äußeres Abschrauben Mehrflügelige Türen
                      </Checkbox>
                      <Checkbox value="inner">Innenblende</Checkbox>
                      <Checkbox value="solid">Stabiles Gitter</Checkbox>
                    </div>
                  </Checkbox.Group>
                </Form.Item>
              ) : null
            }
          </UpdatableField>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="shopWindow" label="Gibt es ein Schaufenster?">
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="accessGates"
        label="Zugangstore zum Grundstück oder den Versicherungsräumen?"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="vendingMachines"
        label="Betrieb von Automaten (mit Geldeinwurf)?"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="hazard"
        label="Gefahren erhöhende Betrieb innerhalb von 15 Metern?"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="buildingType" label="Art des Gebäudes?">
        <Radio.Group>
          <div className="radioBuildingType">
            <Radio value="residential">Wohn & Geschäftshaus</Radio>
            <Radio value="industry">Industrie und Gewerbegebäude</Radio>
            <Radio value="warehouse">Lagergebäude</Radio>
            <Radio value="garage">Garage</Radio>
            <Radio value="kiosk">Kiosk oder Bude</Radio>
          </div>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="buildingLocation" label="Ort des Gebäudes?">
        <Radio.Group>
          <div className="radioBuildingType">
            <Radio value="locality">Innerhalb Ortschaft</Radio>
            <Radio value="shopping">In Einkaufspassage</Radio>
            <Radio value="outsideVillage">Außerhalb Ortschaft</Radio>
            <Radio value="agricultural">In landwirtschaftlichen Gebiet</Radio>
            <Radio value="industrial">In Industrie / Gewerbegebiet</Radio>
            <Radio value="other">Sonstiger Ort</Radio>
          </div>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="buildingAge" label="Alter des Gebäudes?">
        <Radio.Group>
          <div className="radioBuildingType">
            <Radio value="<10">{`< 10 Jahre`}</Radio>
            <Radio value="<20">{`< 20 Jahre`}</Radio>
            <Radio value="<30">{`< 30 Jahre`}</Radio>
            <Radio value=">30">{`> 30 Jahre`}</Radio>
          </div>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="vacancyRate"
        label="Reiner Lagerbetrieb/ Leerstand des Gebäudes > 50%?"
      >
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="buildingOpenSides" label="Offene Seite(n) am Gebäude?">
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="burglarAlarmSystem" label="Einbruchmeldeanlage?">
        <Radio.Group optionType="button" buttonStyle="solid">
          <Radio value={1}>Ja</Radio>
          <Radio value={0}>Nein</Radio>
          <UpdatableField>
            {() =>
              getFieldValue('burglarAlarmSystem') ? (
                <Form.Item
                  name="isVdsApproved"
                  label="VdS-anerkannte Einbruchmeldeanlage?"
                >
                  <Radio.Group optionType="button" buttonStyle="solid">
                    <Radio value={1}>Ja</Radio>
                    <Radio value={0}>Nein</Radio>
                    {Boolean(
                      getFieldValue('burglarAlarmSystem') &&
                        getFieldValue('isVdsApproved')
                    ) && (
                      <Form.Item
                        name="alarmSystemClassification"
                        label="Klassifizierung der Einbruchmeldeanlage?"
                      >
                        <Radio.Group>
                          <div className="radioBuildingType">
                            <Radio value="classA">VdS Klasse A</Radio>
                            <Radio value="classB">VdS Klasse B</Radio>
                            <Radio value="classC">VdS Klasse C</Radio>
                            <Radio value="other">Sonstige</Radio>
                            <Radio value="idk">Ich weiß nicht</Radio>
                          </div>
                        </Radio.Group>
                      </Form.Item>
                    )}
                  </Radio.Group>
                </Form.Item>
              ) : null
            }
          </UpdatableField>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Welche Selbstbeteiligung wünscht Du dir?"
        name="deductibleAmount"
      >
        <Checkbox.Group>
          <div className="scopesCheckboxes">
            <Checkbox value="0">0€</Checkbox>
            <Checkbox value="250">250€</Checkbox>
            <Checkbox value="500">500€</Checkbox>
          </div>
        </Checkbox.Group>
      </Form.Item>
      <span className="assesmentForm-dateOrNext-label">
        Haftpflichtversicherung start termin:
      </span>
      <Form.Item name="liDesiredStartDate">
        <NextPossible disabled={!edit} />
      </Form.Item>
      <span className="assesmentForm-dateOrNext-label">
        Inhaltsversicherung start termin:
      </span>
      <Form.Item name="coDesiredStartDate">
        <NextPossible disabled={!edit} />
      </Form.Item>
    </>
  );
};

const assesmentType = {
  CL: { title: 'Combined', Component: CombinedForm },
  LE: { title: 'Legal', Component: LegalForm }
};

const makeInitialValues = (
  initialValues: Record<string, string[]> | InputData = {}
) => {
  if (!initialValues.scopes) initialValues.scopes = ['general'];

  return initialValues;
};

export const AssesmentModal = ({ id, categoryId }: AssessmentModalProps) => {
  const { locationId } = useParams<{ locationId: string }>();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [edit, setEdit] = useState(false);
  const { data, isLoading } = useAssesment(id, {
    enabled: Boolean(id) && visible
  });
  const updateAssesmentMutation = useCreateAssesment(locationId, categoryId);
  const updateLocationMutation = useUpdateLocationType(locationId);
  const deleteAssesmentMutation = useDeleteAssessment(id);
  const redoAssessmentMutation = useRedoAssesment(id);

  const handleRedoAssesment = () => {
    redoAssessmentMutation
      .mutateAsync('location')
      .then(() => {
        setVisible(false);
      })
      .catch(() => message.error('generalError'));
  };

  const handleCancel = (hide = true) => {
    form.resetFields();
    setEdit(false);
    if (hide) setVisible(false);
  };

  const handleSubmit = () =>
    updateAssesmentMutation
      .mutateAsync({ inputData: modifier(form.getFieldsValue()) })
      .then(() => {
        if (categoryId === 'CL' && form.getFieldValue('locationType'))
          updateLocationMutation
            .mutateAsync({
              locationType: form.getFieldValue('locationType')
            })
            .then(() => handleCancel());
        else handleCancel();
      });

  const handleDelete = () => deleteAssesmentMutation.mutate(id);

  if (isLoading) return <Loader />;

  const { Component } = assesmentType[categoryId as keyof typeof assesmentType];

  const FooterComponent = (
    <div>
      <Popconfirm
        title="Are you sure to let user redo this assessment?"
        onConfirm={handleRedoAssesment}
      >
        <Button danger type="primary">
          Allow User to redo
        </Button>
      </Popconfirm>

      <Button onClick={() => handleCancel(!edit)}>
        {edit ? 'Cancel' : 'Close'}
      </Button>
      {edit ? (
        <Button type="primary" onClick={handleSubmit}>
          Save
        </Button>
      ) : (
        <Button type="primary" onClick={() => setEdit(true)}>
          Edit
        </Button>
      )}
    </div>
  );

  return (
    <>
      <Button onClick={() => setVisible(true)} type="link">
        {id ? 'View' : 'Add'}
      </Button>
      {id && (
        <>
          or
          <Popconfirm
            title="Are you sure to delete this assessment"
            onConfirm={handleDelete}
          >
            <Button danger type="link">
              Delete
            </Button>
          </Popconfirm>
        </>
      )}
      {visible && (
        <Modal
          centered
          footer={FooterComponent}
          title={`${
            assesmentType[categoryId as keyof typeof assesmentType].title
          } Assesment`}
          closable={false}
          cancelText="Close"
          okText="Submit"
          className="add-company-modal"
          open={visible}
        >
          <Upload
            buttonText="Upload"
            queryKeys={['assesment', data?.assessmentId]}
            belongsTo={data?.assessmentId}
            deleteMessage="Are you sure to delete the assessment document?"
            type="assessment_done"
          />
          <Form
            disabled={!edit}
            className="assesmentForm"
            initialValues={makeInitialValues(data?.inputData)}
            layout="vertical"
            form={form}
            style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}
          >
            <Component edit={edit} />
          </Form>
        </Modal>
      )}
    </>
  );
};

interface LegalFormProps {
  edit: boolean;
}
interface CombinedFormProps {
  edit: boolean;
}

interface AssessmentModalProps {
  id: string;
  categoryId: string;
}

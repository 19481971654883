import React from 'react';

import { useAuth } from '@/providers';
import { paths } from '@/routes';
import { useGoToPath, usePathname } from '@/utils';
import { Button, Tabs } from 'antd';
import { identity, values } from 'ramda';
import { Image } from '../Image';

import './styles.scss';

export const Header = () => {
  const { logout } = useAuth();
  const pathname = usePathname();

  const handleChange = useGoToPath(identity);

  const getDefaultKey = () => {
    if (pathname === '/') return pathname;
    else if (pathname === '/company/:id') return '/';
    else
      return values(paths)
        .filter((e) => e !== '/')
        .find((e) => pathname.includes(e));
  };

  return (
    <div className="header">
      <div>
        <Image name="logo" type="png" />
        {/*
          FIXME: using Tabs here is a mistake
          we are disabling browser native features because we are not using links (open in new card)
        */}

        <Tabs
          defaultActiveKey={getDefaultKey()}
          onTabClick={handleChange}
          items={[
            { label: 'Company', key: paths.root },
            { label: 'Products', key: paths.products },
            { label: 'Users', key: paths.users },
            { label: 'Expiring', key: paths.expiring },
            { label: 'Transfer Account', key: paths.transferAccount },
            { label: 'Combo Financials', key: paths.comboFinancials },
            { label: 'Commissions', key: paths.commissions },
            { label: 'Carriers', key: paths.carriers }
          ]}
        ></Tabs>
      </div>
      <Button type="link" onClick={() => logout()}>
        Logout
      </Button>
    </div>
  );
};

import React from 'react';

import { paths } from './paths';
import { Redirect } from 'react-router-dom';
import {
  CompanyOverview,
  Dashboard,
  Products,
  RecommendationPage,
  ComboFinancials,
  UserDashboard,
  Expiring,
  Commissions,
  Carriers,
  TransferAccount
} from '@/pages';
import { Loader } from '@/components';
import { useAuth } from '@/providers';

const AuthRedirect = () => {
  const { isAuthenticated, isLoading } = useAuth();

  if (!isLoading && isAuthenticated) return <Redirect to={paths.root} />;

  return <Loader />;
};

export const routes = [
  {
    path: paths.root,
    component: Dashboard,
    exact: true
  },
  {
    path: paths.users,
    component: UserDashboard
  },
  {
    path: paths.products,
    component: Products
  },
  {
    path: paths.companyOverview,
    component: CompanyOverview
  },
  {
    path: paths.recommendation,
    component: RecommendationPage
  },
  {
    path: paths.comboFinancials,
    component: ComboFinancials
  },
  {
    path: paths.expiring,
    component: Expiring
  },
  { path: paths.authRedirect, component: AuthRedirect },
  {
    path: paths.commissions,
    component: Commissions
  },
  {
    path: paths.carriers,
    component: Carriers
  },
  {
    path: paths.transferAccount,
    component: TransferAccount
  }
];

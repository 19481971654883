import { StarFilled, StarOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';

interface StarButtonProps {
  size?: number;
  disabled?: boolean;
  onChange?: (e: boolean) => void;
  onChangeAddon?: (e: boolean) => void;
  value?: boolean;
}

export const StarButton = ({
  disabled = false,
  onChange,
  onChangeAddon,
  value
}: StarButtonProps) => {
  const Icon = value ? StarFilled : StarOutlined;

  return (
    <Checkbox
      disabled={disabled}
      onChange={(e: CheckboxChangeEvent) => {
        onChange && onChange(e.target.checked);
        onChangeAddon?.(e.target.checked);
      }}
      checked={value}
      className="hideCheckbox"
    >
      <Icon style={{ color: '#fc9303', opacity: 1 }} />
    </Checkbox>
  );
};

import React from 'react';

import { Button } from 'antd';
import { Datepicker } from '.';

interface NextPossibleProps {
  onChange?: (value: string | number | undefined) => void;
  value?: string;
  disabled: boolean;
}

export const NextPossible = ({
  onChange,
  value,
  disabled
}: NextPossibleProps) => (
  <div className="assesmentForm-dateOrNext">
    <Datepicker
      format="DD.MM.YYYY"
      value={value && value !== 'next' ? value : ''}
      onChange={onChange}
      disabled={disabled}
    />
    <span>oder</span>
    <Button
      type={value === 'next' ? 'primary' : 'default'}
      onClick={() => onChange && onChange('next')}
    >
      Nächstmögliches Datum
    </Button>
  </div>
);

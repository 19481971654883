import { concat, join, map, pipe, when } from 'ramda';

export const formatMoney = (
  number: number,
  currency: 'euro' | 'usd' = 'euro'
) => {
  const formats = {
    euro: {
      locale: 'de-DE',
      currency: 'EUR'
    },
    usd: {
      locale: 'en-US',
      currency: 'USD'
    }
  };
  return new Intl.NumberFormat(formats[currency].locale, {
    style: 'currency',
    currency: formats[currency].currency
  }).format(number);
};

export const timestampToDateString = (timestamp: Date | string) => {
  if (!timestamp) return '';
  const d = new Date(timestamp);
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const year = d.getFullYear();

  return pipe(
    map(when((a) => a < 10, pipe(String, concat('0')))),
    join('-')
  )([year, month, day]);
};

export function convertImageToBase64(
  imgUrl: string,
  callback: (base64: string) => void
) {
  const image = new Image();
  image.crossOrigin = 'anonymous';
  image.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (ctx) {
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      ctx.drawImage(image, 0, 0);
      const dataUrl = canvas.toDataURL();
      callback && callback(dataUrl);
    }
  };
  image.src = imgUrl;
}

import React, { StrictMode, Suspense } from 'react';

import 'antd/dist/antd.min.css';
import ReactDOM from 'react-dom/client';
import { Loader } from './components';
import { config } from './config';
import './index.scss';
import { AuthProvider, ReactQueryProvider } from './providers';
import { BaseRouter as App } from './routes';
import './sentry';
import { log, logTable } from './utils';

log('Config:');
logTable(config);

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(rootElement);

root.render(
  <StrictMode>
    <Suspense fallback={<Loader />}>
      <AuthProvider>
        <ReactQueryProvider>
          <App />
        </ReactQueryProvider>
      </AuthProvider>
    </Suspense>
  </StrictMode>
);

import React from 'react';

import { Header } from '@/components';
import { PageLayout } from '@/components/PageLayout';
import { mapWithKey } from '@/utils';
import { BrowserRouter, Switch } from 'react-router-dom';
import { AuthGuard } from './AuthGuard';
import { routes } from './routes';
import { ScrollToTop } from './ScrollToTop';
import { UnauthorizedGuard } from './UnauthorizedGuard';

export const BaseRouter = () => (
  <BrowserRouter>
    <ScrollToTop />
    <UnauthorizedGuard />
    <Header />
    <Switch>
      <PageLayout>{mapWithKey(AuthGuard, 'path', routes)}</PageLayout>
    </Switch>
  </BrowserRouter>
);

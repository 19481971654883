import { createMultiTenantApi } from '@/utils';
import { identity, mergeLeft } from 'ramda';
import { config } from '../config';
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  InternalAxiosRequestConfig
} from 'axios';
export interface CustomAxiosInstance extends AxiosInstance {
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
}

export const api: CustomAxiosInstance = createMultiTenantApi(
  config.backend as string
);

/**
 * @param {import('axios').AxiosRequestConfig} conf
 */
const authInterceptor =
  (token: string) => (config: InternalAxiosRequestConfig) => {
    const headers = mergeLeft(config.headers || {}, {
      Authorization: `Bearer ${token}`
    });

    config.headers = { ...config.headers, ...headers } as AxiosRequestHeaders;

    return config;
  };

const unauthorizedInterceptor = (logout: LogoutFn) => (error: any) => {
  if ([401].includes(error.response?.status)) throw logout();
  else throw error;
};

export const binaryDataApi = axios.create({
  baseURL: config.backend as string,
  responseType: 'blob'
});

export const setAuthInterceptor = (token: string) => {
  api.interceptors.request.use(authInterceptor(token));
  binaryDataApi.interceptors.request.use(authInterceptor(token));
};

export const setUnauthorizedInterceptor = (logout: LogoutFn) => {
  api.interceptors.response.use(identity, unauthorizedInterceptor(logout));
  binaryDataApi.interceptors.response.use(
    identity,
    unauthorizedInterceptor(logout)
  );
};

type LogoutFn = () => void;

import {
  CreateAssessmentData,
  SelfAssessmentValues,
  AssessmentData,
  SelfAssessmentsResponse
} from '@/types/self-assessment';
import { api } from './api';

export const getAssessment = (id: string) =>
  api.get<AssessmentData>(`/assessment/${id}`);

export const getSelfAssessments = (id: string) =>
  api.get<SelfAssessmentsResponse>(`/self-assessment/location/${id}`);

export const createAssessment = ({
  categoryId,
  inputData,
  locationId
}: CreateAssesmentPayload) =>
  api.post(`/assessment`, {
    categoryId,
    inputData,
    locationId,
    complete: true
  });

export const saveSelfAssessment = ({
  assesment,
  locationId
}: SaveSelfAssesmentPayload) =>
  api.post(`/self-assessment/location/${locationId}`, assesment);

export const deleteAssessment = (id: string) => api.delete(`/assessment/${id}`);

export const redoAssessment = async (assessmentId: string) =>
  await api.post(`/admin/assessment/redo/${assessmentId}`);

export interface CreateAssesmentPayload {
  categoryId: string;
  inputData: CreateAssessmentData;
  locationId: string;
}

export interface SaveSelfAssesmentPayload {
  assesment: Partial<SelfAssessmentValues>;
  locationId: string;
}

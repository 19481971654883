import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { isFunction } from '../type';

export const useGoToPath = (path: string | ((a: string) => string)) => {
  const history = useHistory();

  return useCallback(
    (key: string) => history.push(isFunction(path) ? path(key) : path),
    [history, path]
  );
};

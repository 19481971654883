import { InsuranceProduct } from '@/types/insurance';
import { isEmpty } from 'ramda';

export const makeFormattedOptions = (
  values: number[] | null,
  type: 'percent' | 'money'
): null | { value: number; label: string }[] => {
  if (values === null) return values;

  if (isEmpty(values)) return [];

  if (type === 'percent') {
    return values.map((value) => ({
      value,
      label: `${value}%`
    }));
  }

  return values.map((value) => ({
    value,
    label: `€${value.toLocaleString('de-DE')}`
  }));
};

export const isParameterValueDisabled = (
  product: Partial<InsuranceProduct> | undefined,
  name: string
) => product?.disabledFields?.parameterValues?.[name] || false;

export const isAttributeHighlightingDisabled = (
  highlightedAttributes: Record<string, boolean> = {},
  attributeName: string
) => {
  const MAX_HIGHLIGHTED_ATTRIBUTES_AMOUNT = 5;

  const isAttributeHighlighted: boolean =
    highlightedAttributes?.[attributeName];

  // User should be able to remove highlighting
  if (isAttributeHighlighted) return false;

  const amountOfHighlightedAttributes = Object.keys(
    highlightedAttributes
  ).filter((key) => highlightedAttributes[key]).length;

  return amountOfHighlightedAttributes >= MAX_HIGHLIGHTED_ATTRIBUTES_AMOUNT;
};

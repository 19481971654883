export const paths = {
  root: '/',
  users: '/users',
  products: '/products',
  companyOverview: '/overview/:companyId/:locationId?',
  authRedirect: '/auth-redirect',
  recommendation: '/recommendation/:recommendationId',
  comboFinancials: '/combo-financials',
  expiring: '/expiring',
  commissions: '/commissions',
  carriers: '/carriers',
  transferAccount: '/transfer-account'
};

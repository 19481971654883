import { toPairs } from 'ramda';

export type ProductOptions = Record<
  string,
  {
    value: boolean;
    label: string;
    order: number;
    attributes: string[];
    disables: string[];
  }
>;

export function getOptionsList(options: ProductOptions = {}): {
  value: boolean;
  label: string;
  order: number;
  name: string;
  attributes: string[];
  disables: string[];
}[] {
  return toPairs(options)
    .sort((a, b) => a[1].order - b[1].order)
    .map(([name, opt]) => ({ name, ...opt }));
}
